import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import IllustrationFlyBird from '../assets/img/fly_bird.svg';
import IllustrationClock from '../assets/img/clock.svg';
import Counter from '../components/Counter';
import LineThroughWrap from '../components/LineThroughWrap';
import PageHeader from '../components/PageHeader';
import Popover from '../components/Popover';
import ScrollRestoration from '../components/ScrollRestoration';
import { CartItem, catalogStore } from '../stores/CatalogStore';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import { userStore } from '../stores/UserStore';
import { PRODUCT_SELLABLE_LIMIT, BUNDLE_SELLABLE_LIMIT } from '../config';
import PopoverAttentionChangeAddress from '../components/PopoverAttentionChangeAddress';
import RecommendProducts from '../components/RecommendProducts/RecommendProducts';
import useSafariScrollPosition from '../hooks/useSafariScrollPosition/useSafariScrollPosition';
import Gift from './Cart/Gift/Gift';
import {
  JiffyPointsCashbackWidget,
} from './Cart/JiffyPointsCashbackWidget/JiffyPointsCashbackWidget';
import {
  JiffyPointsUseBonusesToggler,
} from './Cart/JiffyPointsUseBonusesToggler/JiffyPointsUseBonusesToggler';
import { checkoutStore } from '../stores/CheckoutStore';
import { PizzaBadge } from '../components/Badges/PizzaBadge/PizzaBadge';
import noProductImg from '../assets/img/no_picture_product.jpg';
import Promocode from './Cart/JiffyPointsUseBonusesToggler/Promocode/Promocode';
import { company } from '../company/Company';

type CartListItemProps = {
  cartItem: CartItem;
  index: number;
};

const CartList = observer(() => {
  const { t } = useTranslation();
  const [isShowPopoverEmptyCart, setIsShowPopoverEmptyCart] = useState(false);
  const handlePopoverEmptyCartDismiss = () => setIsShowPopoverEmptyCart(false);
  const handleEmptyCart = () => {
    mainStore.sendAnalytics(['BI', 'analytics', 'firebase'], {
      name: 'Purchase: cart deleted',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce((sum, item) => sum + item.count, 0),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });
    catalogStore.emptyCart();
    setIsShowPopoverEmptyCart(false);
  };

  // Manage scroll position for Safari, when cart is changed
  useSafariScrollPosition(`${catalogStore.cart}`);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-16 fw-possibly-bold">
        <div className="fs-14">{t('item', { count: catalogStore.totalCartCount })}</div>
        <div
          className="icon icon-remove d-flex flex-center fs-20 s-20"
          onClick={() => setIsShowPopoverEmptyCart(true)}
        />
      </div>
      <div className="br-8 c-bg-white border-tf2 px-8 mt-10 cart-product-list">
        {catalogStore.cart.map((item, i) => (
          <React.Fragment key={item.id}>
            {i > 0 && <div className="h-1 c-bg-tf2 pe-n" />}
            <CartListItem cartItem={item} index={i} />
          </React.Fragment>
        ))}
        <Gift />
      </div>
      <Popover isShow={isShowPopoverEmptyCart} onBackdropDismiss={handlePopoverEmptyCartDismiss}>
        <div className="d-flex align-items-center">
          <div className="icon icon-attention s-20 fs-20 d-flex flex-center c-red mr-12" />
          <div className="fs-21  fw-500">{t('attention')}</div>
        </div>
        <div className="fs-14 lh-20 mt-20">{t('cartPage:emptyCart')}</div>
        <div className="d-flex align-items-center justify-content-end mt-24">
          <div
            className="h-40 mr-20 px-10 lh-40 c-blue fs-14  fw-500"
            onClick={handlePopoverEmptyCartDismiss}
          >
            {t('cancel')}
          </div>
          <div className="button _primary h-40 fs-14" onClick={handleEmptyCart}>
            {t('cartPage:doEmptyCart')}
          </div>
        </div>
      </Popover>
    </>
  );
});

const CartListItem = observer(({ cartItem, index }: CartListItemProps) => {
  const { t } = useTranslation();
  const hasDiscount = cartItem.discountPrice > 0 || cartItem.promoRequiredQuantity > 0;
  const discountPrice = cartItem.discountPrice ? cartItem.discountPrice : cartItem.promoQuantityDiscountPrice;
  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    if (!count) {
      const item = { ...cartItem };
      mainStore.pushAlert(
        'warning',
        `<div class="icon icon-remove s-24 d-flex flex-center fs-18 mr-12"></div><div>${t(
          'productDeleted',
        )}</div><div class="ml-auto c-blue">${t('undo')}</div>`,
        () => {
          catalogStore.cart.splice(index, 0, item);
          mainStore.sendToRN('logAmplitudeEvent', {
            name: 'Purchase: product returned to the cart',
          });
          mainStore.sendToRN('firebaseAnalytics', {
            name: 'purchase_product_returned_to_the_cart',
          });
        },
        '_no-icon _fixed-cart',
      );
    }
    catalogStore.setCartItemCountByProduct(cartItem, count, action, 'cart');
  };
  const sendAnalytic = () => {
    mainStore.sendAnalytics(['analytics', 'firebase'], {
      name: 'Catalog: product page clicked',
      params: {
        product_id: cartItem.id,
        category_id: cartItem.categoryId,
        lvl1_category_id: undefined,
        lvl2_category_id: cartItem.parentCategoryId,
        source: 'cart',
        price: cartItem.priceFormatted,
        final_price: cartItem.discountPrice ? cartItem.discountPriceFormatted : cartItem.priceFormatted,
      },
    });
  };

  const getLimit = (): number => {
    const limit = cartItem.bundle ? BUNDLE_SELLABLE_LIMIT : PRODUCT_SELLABLE_LIMIT;
    return cartItem.sellable > limit ? limit : cartItem.sellable;
  };

  return (
    <div
      className={htmlClasses('cart-item d-flex py-16 overflow-hidden position-relative', {
        '_fashion': !cartItem.isGrocery,
      })}
    >
      <div
        className="w-65 flex-shrink-0 img-contain position-relative"
        style={{
          backgroundImage:
            cartItem.previewImageThumb
              ? `url(${cartItem.previewImageThumb})`
              : `url(${noProductImg})`,
        }}
      >
        <Link
          className="link-abs"
          to={{
            pathname: `/product/${cartItem.productId ?? cartItem.id}`,
            state: { source: 'cart' },
          }}
          replace
          onClick={sendAnalytic}
        />
      </div>
      <div className="pl-12 w-100p d-flex flex-direction-column align-items-start">
        <Link
          className="fs-12 lh-16 mb-4 flex-shrink-0"
          to={{
            pathname: `/product/${cartItem.productId ?? cartItem.id}`,
            state: { source: 'cart' },
          }}
          replace
          onClick={sendAnalytic}
        >
          {cartItem.name}
        </Link>

        <div>
          {!cartItem.isGrocery && (
            <>
              {cartItem.properties.color_text && (
                <div className="product-item__colors flex-shrink-0 mb-5">
                  <div className="product-item__colors-label mr-6 ml-0">{t('color')}:</div>
                  <div
                    className={htmlClasses('product-item__colors-item', {
                      '_border': mainStore.isColorLight(cartItem.properties.color as string || ''),
                      '_multicolor': (cartItem.properties.color_text as string).toLowerCase() === 'multicolor',
                    })}
                    style={{ backgroundColor: cartItem.properties.color as string || undefined }}
                  />
                  <div className="product-item__colors-label">
                    {cartItem.properties.color_text}
                  </div>
                </div>
              )}
              {cartItem.properties.size && (
                <div className="product-item__colors flex-shrink-0 mb-5">
                  <div className="product-item__colors-label ml-0">{t('size')}:</div>
                  <div className="product-item__colors-label">
                    {cartItem.properties.size}
                  </div>
                </div>
              )}
            </>
          )}

          {cartItem.discountPercentage.length > 0 && (
            <div className="br-3 c-bg-ulred fs-8 fw-500 p-5 mb-4 c-red flex-shrink-0 lh-1 mr-4 d-inline-block">
              {t('specialOffer')}:{' '}
              <span className="fw-500">
                {cartItem.discountPercentage}
                {!!cartItem.bundle && `, ${t('productProperties:bundle')}`}
              </span>
            </div>
          )}

          {cartItem.isGrocery && (
            <>
              <PizzaBadge
                className="mr-4"
                commodityGroup={cartItem.commodityGroup}
                count={cartItem.count}
              />
              {cartItem.promoQuantityDiscountPrice > 0 && (
                <div className="br-3 c-bg-ulred fs-8 fw-500 p-5 mb-4 mr-4 c-red flex-shrink-0 lh-1">
                  {t('specialOffer')}:
                  {' '}
                  <span className="fw-500">
                    {mainStore.addCurrencySymbol(cartItem.promoQuantityDiscountPriceFormatted)}
                    {' '}
                    {t('from')}
                    {' '}
                    {cartItem.promoRequiredQuantity}
                    {t('pcs')}
                    {!!cartItem.bundle && `, ${t('productProperties:bundle')}`}
                  </span>
                </div>
              )}

              {!cartItem.promoQuantityDiscountPrice && !cartItem.discountPercentage?.length && !!cartItem.bundle && (
                <div className="br-3 c-bg-ulred fs-8 fw-500 p-5 mb-4 c-red flex-shrink-0 lh-1">
                  <span className="fw-500">
                    {t('productProperties:bundle')}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="mt-auto w-100p flex-shrink-0 d-flex align-items-center justify-content-between">
          {cartItem.isGrocery ? (
            <div className="d-flex align-items-baseline">
              {hasDiscount ? (
                <div className="product-item__price _discount fs-12 flex-shrink-0">
                  {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(discountPrice))}
                </div>
              ) : (
                <div className="product-item__price fs-12 flex-shrink-0">
                  {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(cartItem.price))}
                </div>
              )}
              <div className="fs-12 c-t99">
                ·{' '}
                {hasDiscount && `${mainStore.addCurrencySymbol(cartItem.priceFormatted)} / `}
                {cartItem.pricePerUnit}
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              {hasDiscount ? (
                <div className="product-item__price fs-12 flex-shrink-0">
                  {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(discountPrice))}
                </div>
              ) : (
                <div className="product-item__price fs-12 flex-shrink-0">
                  {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(cartItem.price))}
                </div>
              )}
            </div>
          )}

          <div className="flex-shrink-1">
            {cartItem.count > 0 && (
              <Counter
                value={cartItem.count}
                onChange={handleChangeCount}
                limit={getLimit()}
                height={28}
                addToCartCaption={t('addToBasket')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

const EmptyCart = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="h-100p d-flex flex-direction-column pt-12 mx-24">
      <div className="flex-shrink-0 mt-auto">
        <img className="d-block mx-auto" src={IllustrationFlyBird} alt="" />
        <div className="fs-21 fw-500 mt-24 text-center">{t('cartPage:nothingHere')}</div>
      </div>
      <div className="h-24 flex-shrink-0" />
      <Link className="button _primary flex-shrink-0 mt-auto" to="/">
        {t('goMarket')}
      </Link>
      <div className="h-24 flex-shrink-0" />
    </div>
  );
});

const CartBody = observer(() => {
  const { t } = useTranslation();
  const [isShowPopoverAttentionChangeAddress, setIsShowPopoverAttentionChangeAddress] =
    useState(false);
  const [skuList, setSkuList] = useState<string[]>([]);
  const handleChangeAddress = () => {
    if (userStore.isAuthorized) mainStore.setIsChangeAddressPopover(true);
    else setIsShowPopoverAttentionChangeAddress(true);
  };

  useLayoutEffect(() => {
    userStore.requestPersonalData().catch((error) => error && console.error(error));
    userStore.requestBonuses().catch((error) => error && console.error(error));

    // code below removes last idempotency key, if key was kept in storage
    orderStore.orderIdempotencyCancel().catch((error) => error && console.error(error));
    orderStore.getDeliveryCost().catch((error) => error && console.error(error));
  }, []);

  useEffect(() => {
    setSkuList(catalogStore.cart.map((el) => el.sku));
    //eslint-disable-next-line
  }, [catalogStore.cart.length]);

  const handleShowServiceFeePopover = () => mainStore.setIsShowServiceFeePopover(true);

  const getBonusesCanUse = (): number => {
    return catalogStore.totalCartPrice.bonusesToSpentPounds;
  };

  return (
    <>
      <div className="px-24 pt-12">
        <div className="fs-14 fw-500 fw-possibly-bold">{t('yourAddress')}</div>
        <div
          className="position-relative h-48 br-8 border-teb c-bg-white pt-7 pl-12 pr-40 mt-10"
          onClick={handleChangeAddress}
        >
          <div className="icon icon-chevron-right s-24 position-absolute pe-n fs-14 d-flex flex-center cart-address__icon" />
          <div className="text-truncate fs-13 c-udgray">
            {userStore.deliveryAddress?.shortAddress}
          </div>
          <div className="text-truncate fs-10 c-mgray">
            {userStore.deliveryAddress?.address1}
          </div>
        </div>
        <CartList />
        {orderStore.gift !== null &&
          orderStore.activeGift !== null &&
          orderStore.cartPriceForGift.length > 0 && (
            <div className="alert _warning mt-8">
              {t('cartPage:cartLimitWarning', {
                limit: orderStore.currencySymbol + mainStore.addZeroFraction(
                  orderStore.gift.minimum_order_amount),
              })}
            </div>
          )}

        {!company.isDisableLoyaltyProgram ? <JiffyPointsUseBonusesToggler /> : <Promocode />}

        <div className="br-8 c-bg-white border-tf2 px-16 py-12 mt-16">
          <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
            <div>{t('subtotal')}:</div>
            <div className="ml-10 text-right">
              {mainStore.addCurrencySymbol(catalogStore.totalCartPrice.base)}
            </div>
          </div>
          {catalogStore.isPromoCodeApplied && (
            <div className="d-flex align-items-start justify-content-between fs-16 mb-8 c-red">
              <div>{t('promocode')}:</div>
              <div className="ml-10 text-right">
                - {mainStore.addCurrencySymbol(catalogStore.totalCartPrice.promocodeDiscount)}
              </div>
            </div>
          )}
          {!mainStore.isZero(catalogStore.totalCartPrice.discount) && (
            <div className="d-flex align-items-start justify-content-between fs-16 mb-8 c-red">
              <div>{t('discount')}:</div>
              <div className="ml-10 text-right">
                - {mainStore.addCurrencySymbol(catalogStore.totalCartPrice.discount)}
              </div>
            </div>
          )}
          {checkoutStore.useBonuses && (
            <div className="d-flex align-items-start justify-content-between fs-16 fw-500 mb-8 c-red">
              <div>{t('cartPage:companyPointsApplied')}:</div>
              <div className="ml-10 text-right">
                - {mainStore.addCurrencySymbol(
                mainStore.addZeroFraction(mainStore.convertPenceToPounds(getBonusesCanUse())))}
              </div>
            </div>
          )}
          {!!orderStore.fee.serviceFeeThreshold && (
            <>
              <div
                className="d-flex align-items-start justify-content-between fs-16 mb-8"
                onClick={handleShowServiceFeePopover}
              >
                <div className="d-flex align-items-center">
                  <div>{t('etaPopover:serviceFee')}</div>
                  <div className="ml-4 icon-faq icon c-gray" />
                </div>
                <div className="ml-10 text-right">
                  <LineThroughWrap isWrapped={catalogStore.isFreeServiceFee}>
                    {mainStore.addCurrencySymbol(orderStore.fee.serviceFeeShippingPounds)}
                  </LineThroughWrap>
                </div>
              </div>
              <FreeServiceProgress />
            </>
          )}

          <div className="h-1 pe-n c-bg-tf2 my-12" />

          {company.isShowTaxes &&
            <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
              <div>{t('etaPopover:taxes')}:</div>
              <div className="ml-10 text-right">
                {mainStore.addCurrencySymbol(catalogStore.totalCartPrice.taxAmount)}
              </div>
            </div>
          }

          <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
            <div>{t('etaPopover:deliveryFee')}</div>
            <div className="ml-10 text-right">
              <LineThroughWrap isWrapped={catalogStore.isFreeDelivery}>
                {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(
                  catalogStore.isFreeDelivery ? orderStore.fee.shipping : catalogStore.totalCartPrice.totalDeliveryPricePence) || 0)}
              </LineThroughWrap>
            </div>
          </div>
          <FreeDeliveryProgress />
          <div className="d-flex align-items-start justify-content-between fw-500 fs-21 my-12 fw-possibly-bold">
            <div>{t('total')}:</div>
            <div className="ml-10 text-right">
              {mainStore.addCurrencySymbol(catalogStore.finalPrice)}
            </div>
          </div>
          <div className="h-1 pe-n c-bg-tf2 my-12" />

          <JiffyPointsCashbackWidget />
        </div>
        <div className="h-8" />
        <RecommendProducts sku={skuList} source="reco_cart" shouldShowAsList />
      </div>
      <PopoverAttentionChangeAddress
        isShow={isShowPopoverAttentionChangeAddress}
        onDismiss={() => setIsShowPopoverAttentionChangeAddress(false)}
      />
    </>
  );
});

const FreeDeliveryProgress = observer(() => {
  const { t } = useTranslation();
  const getDeliveryStatus = (): string => {
    if (userStore.personalData.freeDeliveryDaysLeft > 0) {
      return t('cartBoard:freeDeliveryDaysLeft', {
        count: userStore.personalData.freeDeliveryDaysLeft,
      });
    }
    if (catalogStore.isFreeDelivery) {
      return t('cartBoard:freeDelivery');
    }
    return catalogStore.cart.length
      ? t(
        'cartBoard:spendToFreeDelivery',
        { amountLeft: orderStore.currencySymbol + catalogStore.leftUntilFreeDelivery[0] },
      )
      : `${t('etaPopover:deliveryFee')} ${mainStore.addCurrencySymbol('0')} — ${
        mainStore.addCurrencySymbol(
          mainStore.convertPenceToPounds(catalogStore.totalCartPrice.totalDeliveryPricePence) || 0)
      }`;
  };

  if (catalogStore.isFreeDelivery || !catalogStore.isMinimalOrderFeePassed) return <></>;

  return (
    <>
      <div className="d-flex align-items-center c-udgray">
        <div
          className={htmlClasses(
            'icon icon-sign-i s-14 rounded-circle d-flex flex-center mr-5 fs-9 fw-500 c-white',
            userStore.personalData.freeDeliveryDaysLeft > 0 ||
            catalogStore.isFreeDelivery ? 'c-bg-lgreen' : 'c-bg-dorange',
          )}
        />
        <div className="fw-500 fs-11">{getDeliveryStatus()}</div>
      </div>
      <div className="progress-bar h-4 c-bg-sgray my-6">
        <div
          className={htmlClasses(
            'progress-bar__bar',
            catalogStore.isFreeDelivery ? 'c-bg-lgreen' : 'c-bg-dorange',
          )}
          style={{ width: catalogStore.leftUntilFreeDelivery[1], transition: 'all 0.3s' }}
        />
      </div>
    </>
  );
});

const FreeServiceProgress = observer(() => {
  const { t } = useTranslation();

  const getServiceStatus = (): string => {
    if (catalogStore.isFreeServiceFee) {
      return t('cartBoard:freeService');
    }

    if (catalogStore.cart.length > 0) {
      return t('cartBoard:spendToFreeService', {
        amountLeft: orderStore.currencySymbol + catalogStore.leftUntilFreeServiceFee[0],
      });
    }

    return `${t('etaPopover:freeService')} ${mainStore.addCurrencySymbol(
      '0')} — ${mainStore.addCurrencySymbol(orderStore.fee.serviceFeeShippingPounds)}`;
  };

  if (catalogStore.isFreeServiceFee || !orderStore.fee.serviceFeeThreshold || !catalogStore.isMinimalOrderFeePassed) return <></>;

  return (
    <>
      <div className="d-flex align-items-center c-udgray">
        <div
          className={htmlClasses(
            'icon icon-sign-i s-14 rounded-circle d-flex flex-center mr-5 fs-9 fw-500 c-white',
            catalogStore.isFreeServiceFee ? 'c-bg-lgreen' : 'c-bg-dorange',
          )}
        />

        <div className="fw-500 fs-11 fw-possibly-bold">{getServiceStatus()}</div>
      </div>

      <div className="progress-bar h-4 c-bg-sgray my-6">
        <div
          className={htmlClasses(
            'progress-bar__bar',
            catalogStore.isFreeServiceFee ? 'c-bg-lgreen' : 'c-bg-dorange',
          )}
          style={{ width: catalogStore.leftUntilFreeServiceFee[1], transition: 'all 0.3s' }}
        />
      </div>
    </>
  );
});

const Closed = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="h-100p d-flex flex-direction-column overflow-hidden">
      <div className="h-100p overflow-auto pt-12 px-24">
        {mainStore.isOverdueWarehouseTime() ? (
          <>
            <img className="d-block mx-auto" src={IllustrationClock} alt="" />
            <div className="text-center fs-21  fw-500 c-udgray mt-24">
              {t('cartPage:notWorkingTitle')}
            </div>
            <div className="text-center fs-14  fw-500 c-udgray mt-5 w-max-290 mx-auto">
              {t('cartPage:notWorkingText')}
            </div>
          </>
        ) : (
          <>
            <img className="d-block mx-auto" src={IllustrationClock} alt="" />
            <div className="text-center fs-21  fw-500 c-udgray mt-24">
              {t('cartPage:startWorking')}
            </div>
            <div className="text-center fs-18  fw-500 mt-5 c-udgray">
              {t('cartPage:closedTill', {
                time: orderStore.etaCalculation?.warehouse.availability.opening || '',
              })}
            </div>
          </>
        )}
        {catalogStore.cart.length > 0 && (
          <>
            <div className="text-center fs-19  mt-40 c-udgray">
              {t('yourCart')}
            </div>
            <CartList />
          </>
        )}
      </div>
      <Link className="button _block _primary flex-shrink-0 mx-24 mt-24" to="/">
        {t('goBackCatalog')}
      </Link>
      <div className="h-24 flex-shrink-0" />
    </div>
  );
});

export const CartAwaiting = observer(() => {
  return (
    <div className="px-24 pt-12">
      <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7">
        <Skeleton className="h-18 w-100 br-30" />
        <Skeleton className="h-48 br-8 mt-16" />
        <Skeleton className="h-18 br-30 w-70 mt-24" />
        <Skeleton className="h-200 br-8 mt-16" />
        <Skeleton className="h-18 w-100 br-30 mt-24" />
        <Skeleton className="h-48 br-8 mt-16" />
        <Skeleton className="h-200 br-8 mt-16" />
      </SkeletonTheme>
    </div>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    setIsOpen(orderStore.etaCalculation?.warehouse.availability.availableNow || false);
    //eslint-disable-next-line
  }, [orderStore.etaCalculation?.warehouse.availability.availableNow]);

  useEffect(() => {
    mainStore.sendAnalytics(['BI', 'analytics', 'yaMetrika'], {
      name: 'Purchase: cart viewed',
      params: {
        cart_id: undefined,
        in_opening_hours: false,
        products_amount: catalogStore.cart.reduce((sum, item) => sum + item.count, 0),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'view_cart',
      params: {
        currency: orderStore.currency.toUpperCase(),
        value: mainStore.toFloat(
          catalogStore.finalPrice,
        ),
        items: catalogStore.cartForFirebase,
      },
    });
  }, []);

  useEffect(() => {
    if (!userStore.personalData.isAdult && catalogStore.isAdultItemInCart) {
      mainStore.setIsAgeRestrictionPopover(true);
    }
  }, [userStore.personalData.isAdult]);

  return (
    <>
      <ScrollRestoration pageName="cart" />
      <PageHeader title={t('cart')} />
      <div className={htmlClasses('scroll-layout h-100p', { 'overflow-hidden': isOpen === null })}>
        {isOpen === null ? (
          <CartAwaiting />
        ) : !isOpen ? (
          <Closed />
        ) : catalogStore.cart.length > 0 ? (
          <CartBody />
        ) : (
          <EmptyCart />
        )}
      </div>
    </>
  );
});
