import { format } from 'date-fns';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useHistory } from 'react-router-dom';
import { Order, OrderShort } from '../api/Order';
import IllustrationFlyBird from '../assets/img/fly_bird.svg';
import LogoBaqal from '../assets/img/logo_baqal.svg';
import LogoSwifftB from '../assets/img/logo_swifft_b.png';
import PageHeader from '../components/PageHeader';
import ScrollRestoration from '../components/ScrollRestoration';
import { mainStore } from '../stores/MainStore';
import { orderStore, OrderStatusStore } from '../stores/OrderStore';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import ExternalLink from '../components/ExternalLink';
import { CompanyName } from '../company/interface';
import { company } from '../company/Company';
import FCButton from '../components/FCButton';

type OrderItemProps = {
  order: Order | OrderShort;
  isList: boolean;
};

const illustration: Record<CompanyName, string> = {
  [CompanyName.Jiffy]: IllustrationFlyBird,
  [CompanyName.Baqal]: LogoBaqal,
  [CompanyName.Swifft]: LogoSwifftB,
};

export const OrderItem = observer(({ order, isList }: OrderItemProps) => {
  const { t } = useTranslation();
  const [publicStatus, setPublicStatus] = useState<OrderStatusStore>('accepted');
  const correctOrderId = (id: string): string => {
    return id.length >= 14 ? id.replace(/(.{6})$/, '-$1') : id;
  };

  useEffect(() => {
    let status: OrderStatusStore;
    switch (order.public_status) {
      case 'in_delivery':
        status = 'inDelivery';
        break;
      case 'ready_to_ship':
        status = 'readyToShip';
        break;
      case 'ready_for_pickup':
        status = 'readyForPickup';
        break;
      default:
        status = order.public_status;
        break;
    }
    setPublicStatus(status);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Link
        className={!isList ? 'pe-n' : ''}
        to={`/${
          order.public_status === 'delivered' ||
          order.public_status === 'failed' ||
          order.public_status === 'cancelled'
            ? 'order'
            : 'status'
        }/${order.id}`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className={htmlClasses(
                'icon s-24 fs-18 d-flex flex-center ml-n5 c-blue mr-5',
                { 'icon-accepted': order.public_status === 'accepted' },
                { 'icon-picking': order.public_status === 'picking' },
                { 'icon-picking': order.public_status === 'ready_to_ship' },
                { 'icon-indelivery': order.public_status === 'in_delivery' },
                { 'icon-flag': order.public_status === 'delivered' },
                { 'icon-blocked': order.public_status === 'cancelled' },
                { 'icon-blocked': order.public_status === 'failed' },
              )}
            />
            <div className="fs-14 c-black">
              {t(`statusWidget:${publicStatus}.name`)}
            </div>
          </div>
          <div className="fs-14 fw-500 c-gray">
            {format(new Date(order.updated_at), 'HH:mm d MMM, eee')}
          </div>
        </div>
        <div className="d-flex align-items-top justify-content-between mt-12  fs-14 c-black">
          <div className="mr-12">
            {t('order')} #{correctOrderId(order.order_id)}
          </div>
          <div>
            {mainStore.addCurrencySymbol(
              mainStore.convertPenceToPounds(order.paid_total || 0), order.currency)}
          </div>
        </div>
        <div className="fs-12 c-gray mt-12">
          {order.itemsCount.actual === order.itemsCount.requested
            ? t('item', { count: order.itemsCount.requested })
            : t('itemOf', { num1: order.itemsCount.actual, num2: order.itemsCount.requested })}
        </div>
        {order.address !== null && (
          <div className="fs-12 c-gray mt-12 lh-16">
            {`${order.address.zip || ''}, ${order.address.address_1 || ''}, ${
              order.address.address_2 || ''
            }, ${order.address.city || ''}, ${order.address.country_id || ''}`.replace(
              /^(, )|( ,)|(, )*$/g,
              '',
            )}
          </div>
        )}
      </Link>
      <div className="d-flex justify-content-end fs-14 c-blue mt-10">
        {company.isIncludedIn([CompanyName.Baqal]) ? (
          <ExternalLink
            className="p-8 c-blue"
            href={company.config.links.whatsapp}
            targetBlank
          >
            {t('getHelp')}
          </ExternalLink>
        ) : <FCButton className="p-8 c-blue" title={t('getHelp')} />}
      </div>
    </>
  );
});

let isLoading = false;
let downloadIsPossible = false;

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const refLoaderTrigger = useRef<HTMLDivElement>(null);
  const { isObservableElementVisible, stopObserver, runObserver } = useIntersectionObserver();
  const [curPage, setCurPage] = useState(orderStore.currentOrdersPage);

  useEffect(() => {
    if (history.action === 'POP' && orderStore.orderList?.length) {
      downloadIsPossible = true;
      return;
    }
    setCurPage(1);
    isLoading = true;
    orderStore
      .requestOrders()
      .then((e) => {
        downloadIsPossible = e;

        if (downloadIsPossible) {
          runObserver(refLoaderTrigger);
        }
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        isLoading = false;
      });
    // eslint-disable-next-line
  }, [history.action]);

  useEffect(() => {
    if (orderStore.currentOrdersPage === 1 || orderStore.currentOrdersPage === curPage) return;
    isLoading = true;
    orderStore
      .requestOrders(orderStore.currentOrdersPage)
      .then((e) => {
        downloadIsPossible = e;

        if (!downloadIsPossible) {
          stopObserver();
        }
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        isLoading = false;
      });
    //eslint-disable-next-line
  }, [orderStore.currentOrdersPage]);

  useEffect(() => {
    if (isLoading || !isObservableElementVisible || !downloadIsPossible) {
      /**
       * No need to do something if:
       * - orders is loading
       * - user did not scroll down to "fetch more" hidden component
       * - if downloading new items is denied, it could be in case when user achieved end of list
       * */
      return;
    }

    orderStore.setCurrentOrdersPage(orderStore.currentOrdersPage + 1);
  }, [isObservableElementVisible]);

  return (
    <>
      <ScrollRestoration pageName="orders" />
      <PageHeader title={t('myOrder_other')} />
      <div
        className={htmlClasses('scroll-layout h-100p px-24', {
          'overflow-hidden': !orderStore.orderList,
        })}
      >
        {orderStore.orderList !== null ? (
          orderStore.orderList.length > 0 ? (
            <>
              <div className="smt-16 pt-12">
                {orderStore.orderList.map((item) => (
                  <div className="sm-item c-bg-white br-8 p-16 d-block border-tf2" key={item.id}>
                    <OrderItem order={item} isList={true} />
                  </div>
                ))}
              </div>
              <div ref={refLoaderTrigger} />
              <div className="h-24" />
            </>
          ) : (
            <div className="d-flex h-100p flex-direction-column justify-content-center">
              <div className="flex-shrink-0">
                <img
                  className="d-block mx-auto orders-page__illustration"
                  src={illustration[company.name]}
                  alt=""
                />
                <div className="fs-21 mt-24  text-center c-udgray fw-500">
                  {t('cartPage:nothingHere')}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="smt-16 pt-12">
            <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7">
              <Skeleton className="sm-item br-8 h-200" count={5} />
            </SkeletonTheme>
          </div>
        )}
      </div>
    </>
  );
});
