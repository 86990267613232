import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useRef, useEffect } from 'react';
import { orderStore } from '../../../stores/OrderStore';
import { catalogStore } from '../../../stores/CatalogStore';
import ProductItem from '../../../components/ProductItem';
import { Link } from 'react-router-dom';
import { BESTSELLERS_ITEMS_LENGTH } from '../constants';
import { useSliderBlink } from '../hooks/useSliderBlink';
import SessionStorage from '../../../stores/SessionStorage';
import { SESSION_STORAGE } from '../../../config';

const Bestsellers = observer(() => {
  const { t } = useTranslation();
  const observedElementRef = useRef<HTMLDivElement>(null);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const { setBlinkAction } = useSliderBlink(observedElementRef, scrollElementRef);

  useEffect(() => {
    if (!orderStore.etaCalculation?.warehouse?.code) return;
    catalogStore
      .requestBestsellers(true)
      .then(() => {
        if (SessionStorage.get(SESSION_STORAGE.BLINK_ACTION_CALLED)) return;

        setBlinkAction();
      })
      .catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [orderStore.whCode]);

  // check that list has items to show
  const isBestsellersListAvailable = catalogStore.bestsellersProducts.length > 0

  return (
    <>
      {isBestsellersListAvailable && (
        <>
          <div className="category-title ff-feixen fw-500 mx-16 mb-8 mt-32">{t('bestsellers')}</div>

          <div className="scroll-list _16 hide-scroll-bar pb-8 mb-n8" ref={scrollElementRef}>
            {catalogStore.bestsellersProducts.slice(0, BESTSELLERS_ITEMS_LENGTH).map((item) => (
              <ProductItem product={item} source="bestsellers_slider" isWithinSlider key={item.id} />
            ))}

            {catalogStore.bestsellersProducts.length > BESTSELLERS_ITEMS_LENGTH && (
              <Link className="product-item border-0 c-bg-none flex-center" to="/bestsellers">
                <div className="text-center">
                  <div className="icon icon-arrow-long s-60 d-flex flex-center rounded-circle fs-24 c-bg-dgreen c-white" />

                  <div className="ff-feixen mt-8 lh-20 c-black">{t('seeAll')}</div>
                </div>
              </Link>
            )}
          </div>
        </>
      )}

      <div ref={observedElementRef} />
    </>
  );
});

export default Bestsellers;
