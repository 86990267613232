import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { REQUEST_ORDER_STATUS_INTERVAL } from '../config';
import { OrderStatusStore, orderStore } from '../stores/OrderStore';
import { userStore } from '../stores/UserStore';
import { mainStore } from '../stores/MainStore';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ iframeUrl?: string }>();
  const { id } = useParams<{ id: string }>();
  const [orderStatus, setOrderStatus] = useState<OrderStatusStore | null>(null);

  useLayoutEffect(() => {
    if (!userStore.isAuthorized) return;
    if (orderStatus === 'none') return;
    const getOrderStatus = () => {
      if (mainStore.inBackground) return;
      orderStore
        .requestOrder(id)
        .then((e) => {
          if (!e) {
            setOrderStatus('none');
            return;
          }
          if (e.public_status === 'in_delivery') setOrderStatus('inDelivery');
          else setOrderStatus('none');
        })
        .catch(() => {
          setOrderStatus('none');
        });
    };
    getOrderStatus();
    const interval = setInterval(getOrderStatus, REQUEST_ORDER_STATUS_INTERVAL);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [id, orderStatus, userStore.isAuthorized, mainStore.inBackground]);

  useEffect(() => {
    if (!state?.iframeUrl || orderStatus === 'none') {
      history.length === 1 ? history.push('/') : history.goBack();
    }
  }, [state?.iframeUrl, history, orderStatus]);

  return (
    <>
      <div className="flex-shrink-0 h-50 d-flex align-items-center px-7">
        <div
          className="icon icon-arrow-back d-flex flex-center s-50 fs-20 c-black"
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />
        <h3 className="w-100p text-center lh-20 pr-50">
          {t('statusWidget:inDelivery.trackTitle')}
        </h3>
      </div>
      {state?.iframeUrl?.length === 0 || orderStatus !== 'inDelivery' ? (
        <></>
      ) : (
        <iframe
          className="h-100p w-100p"
          frameBorder={0}
          title="Courier tracking"
          src={state?.iframeUrl}
        />
      )}
    </>
  );
});
