import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { catalogStore } from '../../../stores/CatalogStore';
import React, { useState, useEffect, useRef } from 'react';
import { mainStore } from '../../../stores/MainStore';
import { orderStore } from '../../../stores/OrderStore';
import Bestsellers from '../Bestsellers/Bestsellers';
import ProductItem from '../../../components/ProductItem';
import { Link } from 'react-router-dom';
import { GRAB_AGAIN_ITEMS_LENGTH } from '../constants';
import { useSliderBlink } from '../hooks/useSliderBlink';
import SessionStorage from '../../../stores/SessionStorage';
import { SESSION_STORAGE } from '../../../config';
import { Product } from '../../../types/Product/interface';

const GrabAgain = observer(() => {
  const { t } = useTranslation();
  const observedElementRef = useRef<HTMLDivElement>(null);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const { setBlinkAction } = useSliderBlink(observedElementRef, scrollElementRef);

  const purchasedItemsList = catalogStore.purchasedItemsList.filter((product) => product.sellable);
  const [grabAgainProducts, setGrabAgainProducts] = useState<Product[] | null>(
    purchasedItemsList.length ? purchasedItemsList : null,
  );
  const handleSeeAllClick = () => {
    mainStore.sendToRN('analytics', {
      name: 'Catalog: Grabagain visited',
      params: {
        source: 'homepage',
      },
    });
  };

  useEffect(() => {
    if (!orderStore.etaCalculation?.warehouse?.code) return;
    catalogStore
      .fetchPurchasedItems(true)
      .then(() =>
        setGrabAgainProducts(catalogStore.purchasedItemsList.filter((product) => product.sellable)),
      )
      .catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [orderStore.etaCalculation?.warehouse?.code]);

  useEffect(() => {
    if (SessionStorage.get(SESSION_STORAGE.BLINK_ACTION_CALLED)) return;
    if (grabAgainProducts?.length) {
      setBlinkAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grabAgainProducts?.length]);

  return (
    <>
      {!grabAgainProducts?.length && <Bestsellers />}

      {!!grabAgainProducts?.length && (
        <>
          <div className="category-title ff-feixen fw-500 mx-16 mb-8 mt-32">{t('grabAgain')}</div>

          <div className="scroll-list _16 hide-scroll-bar pb-8 mb-n8" ref={scrollElementRef} >
            {grabAgainProducts.slice(0, GRAB_AGAIN_ITEMS_LENGTH).map((item) => (
              <ProductItem product={item} source="grabagain_slider" isWithinSlider key={item.id} />
            ))}

            {grabAgainProducts.length > GRAB_AGAIN_ITEMS_LENGTH && (
              <Link
                className="product-item border-0 c-bg-none flex-center"
                onClick={handleSeeAllClick}
                to={{ pathname: '/favorites', state: { activeTabNum: 1 } }}
              >
                <div className="text-center">
                  <div className="icon icon-arrow-long s-60 d-flex flex-center rounded-circle fs-24 c-bg-dgreen c-white" />

                  <div className="ff-feixen mt-8 lh-20 c-black">{t('seeAll')}</div>
                </div>
              </Link>
            )}
          </div>
        </>
      )}

      <div ref={observedElementRef} />
    </>
  );
});

export default GrabAgain;
