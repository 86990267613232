import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import { mainStore } from '../stores/MainStore';
import { CSSTransition } from 'react-transition-group';
import ReferralBanner from '../components/ReferralBanner';
import { userStore } from '../stores/UserStore';
import Icon, { IconProps } from '../components/ui/Icon/Icon';
import { company } from '../company/Company';
import ExternalLink from '../components/ExternalLink';
import { CompanyName } from '../company/interface';

interface IMenuItemRoute {
  pathname: string;
  state?: Record<string, any>;
}

interface IMenuItem {
  title: string;
  icon: { name: string; isSvg?: false } | { name: IconProps['type']; isSvg: true };
  route: IMenuItemRoute;
  isHide?: boolean;
}

type MenuItemProps = {
  route: IMenuItemRoute;
  children: ReactNode;
}

const MenuItem = observer(({ route, children }: MenuItemProps) => {
  const handleClick = () => {
    if (route.pathname === '/chat') {
      window.fcWidget?.show();
      window.fcWidget?.open();
      return;
    }
    mainStore.setIsVisibleMainMenu(false);
  };

  if (route.pathname === '/chat' && company.isIncludedIn([CompanyName.Baqal])) {
    return (
      <ExternalLink
        className="h-48 d-flex align-items-center c-udgray fs-16"
        href={company.config.links.whatsapp}
        targetBlank
      >
        {children}
      </ExternalLink>
    );
  }

  return (
    <Link
      className="h-48 d-flex align-items-center c-udgray fs-16"
      to={route.pathname !== '/chat' ? route : ''}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const mainMenuRef = useRef<HTMLDivElement>(null);
  const [isHideReferral, setIsHideReferral] = useState(true);
  const menuList: IMenuItem[] = [
    {
      title: t('companyPoints'),
      icon: { name: company.config.points?.icon || 'coins', isSvg: true },
      route: { pathname: '/points' },
      isHide: company.isDisableLoyaltyProgram,
    },
    {
      title: t('myProfile'),
      icon: { name: 'profile', isSvg: true },
      route: { pathname: '/profile' },
    },
    {
      title: t('paymentMethods'),
      icon: { name: 'payment-card' },
      route: { pathname: '/payment-methods' },
      isHide: company.isHidePaymentMethodsPage,
    },
    {
      title: t('referralProgram'),
      icon: { name: 'discount' },
      route: {
        pathname: '/referral',
        state: { source: 'sidebar link' },
      },
      isHide: isHideReferral,
    },
    {
      title: t(company.isHideFavourites ? 'purchasedItems' : 'faves'),
      icon: { name: 'favorite' },
      route: { pathname: '/favorites' },
    },
    {
      title: t('myOrder_other'),
      icon: { name: 'bag', isSvg: true },
      route: { pathname: '/orders' },
    },
    {
      title: t('notificationsSettings'),
      icon: { name: 'bell', isSvg: true },
      route: { pathname: '/notifications' },
    },
    {
      title: t('chatSupport'),
      icon: { name: 'chat', isSvg: true },
      route: { pathname: '/chat' },
    },
    {
      title: t('about'),
      icon: { name: 'info' },
      route: { pathname: '/about' },
    },
  ];
  const menuListWithState = menuList.map((item) => {
    if (item.route.state) {
      item.route.state.fromMenu = true;
    } else {
      item.route.state = { fromMenu: true };
    }
    return item;
  });

  useEffect(() => {
    setIsHideReferral(!userStore.isAuthorized || !mainStore.analytics.totalSuccessfulOrders);
  }, [userStore.isAuthorized, mainStore.analytics.totalSuccessfulOrders]);

  useEffect(() => {
    return () => {
      window.fcWidget?.hide();
      window.fcWidget?.close();
    };
  }, []);

  useEffect(() => {
    mainStore.setIsShowMainMenu(false);
  }, [location]);

  return (
    <CSSTransition
      classNames="main-menu"
      in={mainStore.isShowMainMenu}
      timeout={{ enter: 300, exit: 200 }}
      mountOnEnter
      unmountOnExit
      nodeRef={mainMenuRef}
    >
      <div
        className="main-menu"
        style={{ visibility: mainStore.isVisibleMainMenu ? 'visible' : 'hidden' }}
        ref={mainMenuRef}
      >
        <div className="px-4">
          <PageHeader
            title={t('menu')}
            iconClickAction={() => mainStore.setIsShowMainMenu(false)}
          />
        </div>
        <div className="scroll-layout px-24 pt-10">
          {menuListWithState.map((item, i) => (
            <React.Fragment key={i}>
              {item.isHide ? (
                <></>
              ) : (
                <>
                  <MenuItem route={item.route}>
                    {item.icon.isSvg ? (
                      <Icon type={item.icon.name} size={24} className="d-flex flex-center c-udgray mr-20" />
                    ) : (
                      <div
                        className={`icon icon-${item.icon.name} d-flex flex-center s-24 fs-24 c-udgray mr-20`}
                      />
                    )}
                    {item.title}
                    {item.route.pathname === '/points' && (
                      <div className="ml-auto">
                        {userStore.isAuthorized ? (
                          <div className="c-black fs-16 fw-500 lh-36 ">{userStore.bonuses}</div>
                        ) : (
                          <div className="c-bg-corange c-white fw-500 fs-16 lh-22 px-8 py-3 br-22">
                            {t('new')}
                          </div>
                        )}
                      </div>
                    )}
                  </MenuItem>
                  <div className="pe-n h-1 c-bg-tf2 hide-last-child" />
                </>
              )}
            </React.Fragment>
          ))}
        </div>
        {!isHideReferral && (
          <div className="pt-16 mt-auto mx-16 flex-shrink-0">
            <ReferralBanner source="sidebar banner" />
          </div>
        )}
        <div className="h-24 flex-shrink-0" />
      </div>
    </CSSTransition>
  );
});
