import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ScrollRestoration from '../../components/ScrollRestoration';
import { catalogStore } from '../../stores/CatalogStore';
import { userStore } from '../../stores/UserStore';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import ProductList from '../../components/ProductList';
import { Tab } from './constants';
import EmptyFavoritesList from './EmptyFavoritesList';
import { mainStore } from '../../stores/MainStore';
import { company } from '../../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const fetchMoreElementRef = useRef<HTMLDivElement>(null);
  const { runObserver, isObservableElementVisible } = useIntersectionObserver();
  const history = useHistory();
  const { state } = useLocation<{ activeTabNum: number; fromMenu?: boolean }>();
  const [activeTab, setActiveTab] = useState(
    state?.activeTabNum || (company.isHideFavourites ? Tab.Purchases : Tab.Favourite));
  const [isLoading, setIsLoading] = useState(true);
  const [isCanFetchFavouritesMore, setIsCanFetchFavouritesMore] = useState(true);
  const [isCanFetchPurchasesMore, setIsCanFetchPurchasesMore] = useState(true);
  const [isCanFetchRecommendsMore, setIsCanFetchRecommendsMore] = useState(true);
  const [currentItems, setcurrentItems] = useState<Array<any>>([]);

  // Add "You might also like" in JSON
  const tabs: string[] = t('favTabs', { returnObjects: true });

  const updateListByTab = (activeTab: number) => {
    // code below changes actual list, when user switches tabs
    switch (activeTab) {
      case Tab.Favourite:
        setcurrentItems(catalogStore.favoritesList);
        break;
      case Tab.Purchases:
        setcurrentItems(catalogStore.purchasedItemsList);
        break;
      case Tab.Recommends:
        setcurrentItems(catalogStore.recommendItems);
        break;
    }
  };

  const getItemsListByTab = (activeTab: number, isForce?: boolean) => {
    // Code below gets additional items by the tab any time when it is called
    switch (activeTab) {
      case Tab.Purchases:
        catalogStore.fetchPurchasedItems(isForce)
          .catch((error) => error && console.error(error))
          .then((isCanFetchPurchasesMore) => {
            /**
             * If last fetch returns 0 items, that means that we got all list from server and
             * fetch more flag for purchases can be disabled
             * */
            setIsCanFetchPurchasesMore(isCanFetchPurchasesMore);
            updateListByTab(Tab.Purchases);
          }).finally(() => {
          setIsLoading(false);
        });

        break;

      case Tab.Favourite:
        catalogStore.fetchFavorites()
          .catch((error) => error && console.error(error))
          .then((result) => {
            /**
             * If last fetch returns 0 items, that means that we got all list from server and
             * fetch more flag for favorites can be disabled
             * */
            setIsCanFetchFavouritesMore(!!result);
            updateListByTab(Tab.Favourite);
          }).finally(() => {
          setIsLoading(false);
        });

        break;

      case Tab.Recommends:
        catalogStore.requestRecommendProducts([], 'purchased_items')
          .catch((error) => error && console.error(error))
          .then((result) => {
            setIsCanFetchRecommendsMore(!!result);
            updateListByTab(Tab.Recommends);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }
  };

  useEffect(() => {
    /**
     * Code below gets list of items for particular active tab
     *
     * Should be called only once, when user open favourites page with any tab
     * */
    setIsLoading(true);
    getItemsListByTab(activeTab, true);

    runObserver(fetchMoreElementRef);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    /**
     * Code below runs any time when user scroll down to the end of the list
     * and we need to upload more items
     * */
    if (!isObservableElementVisible) {
      return;
    }

    const shouldUpdatePurchases = activeTab === Tab.Purchases && isCanFetchPurchasesMore;
    const shouldUpdateFavourite = activeTab === Tab.Favourite && isCanFetchFavouritesMore;
    const shouldUpdateRecommends = activeTab === Tab.Recommends && isCanFetchRecommendsMore;

    if (shouldUpdatePurchases || shouldUpdateFavourite || shouldUpdateRecommends) {
      // Code below will be called only when user on particular page and specific for tab fetch more item flag is enabled
      setIsLoading(true);

      getItemsListByTab(activeTab);
      return;
    }

    // eslint-disable-next-line
  }, [
    isCanFetchPurchasesMore, isObservableElementVisible, isCanFetchFavouritesMore, activeTab,
    isCanFetchRecommendsMore,
  ]);

  useEffect(() => {
    updateListByTab(activeTab);
  }, [activeTab]);

  const handleGoBackClick = () => {
    if (state && state.fromMenu) {
      history.push('/');
      mainStore.setIsVisibleMainMenu(true);
      return;
    }
    history.length === 1 ? history.push('/') : history.goBack();
  };

  return (
    <>
      <ScrollRestoration pageName="favourites" />

      <div className="d-flex h-50 flex-shrink-0 align-items-center">
        <div
          className="icon icon-arrow-back d-flex flex-center s-50 fs-20 c-black"
          onClick={handleGoBackClick}
        />

        <h2 className="text-center w-100p lh-20 pr-50">
          {t(company.isHideFavourites ? 'purchasedItems' : 'faves')}
        </h2>
      </div>

      {!company.isHideFavourites && (
        <div className="text-nowrap overflow-auto px-24 flex-shrink-0 hide-scroll-bar">
          {tabs.map((item, i) => (
            <div
              className={htmlClasses('badge _subcategory', { _active: i === activeTab })}
              key={i}
              onClick={() => setActiveTab(i)}
            >
              {item}
            </div>
          ))}
        </div>
      )}

      <div className="scroll-layout h-100p mt-16 px-24">
        <ProductList
          // Because we use "EmptyFavoritesList", no need to show info that all items was sold out
          hideSoldOutInfo
          isLoading={isLoading}
          productList={currentItems}
          source={!activeTab ? 'favorites' : 'purchased'}
          showThreeInLine
          notGroupOutOfStock={true}
        />

        {!isLoading && currentItems.length === 0 && (
          <EmptyFavoritesList
            userName={userStore.fullName.length > 0 ? `, ${userStore.fullName} ` : ''}
          />
        )}
        <div ref={fetchMoreElementRef} />
        <div className="h-24" />
      </div>

      {/*{activeTab === Tab.Purchases && (
        <PaginatedPurchased />
      )*/}
    </>
  );
});
