import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';

export default observer(() => {
  const { t } = useTranslation();
  const onDismiss = () => mainStore.setIsZeroCartPopover(false);

  return (
    <Popover isShow={mainStore.isZeroCartPopover} onBackdropDismiss={onDismiss}>
      <div className='d-flex align-items-center'>
        <div className='icon icon-attention s-20 fs-20 d-flex flex-center c-red mr-12' />
        <div className='fs-21 fw-500'>{t('attention')}</div>
      </div>
      <div className='fs-14 lh-20 mt-20'>
        {t('errors:minimalCartPrice', { currencySymbol: orderStore.currencySymbol })}
      </div>
      <div className='button _primary w-100p mt-32' onClick={onDismiss}>
        {t('understood')}
      </div>
    </Popover>
  );
});
