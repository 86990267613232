import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { mainStore } from '../stores/MainStore';

type PageHeaderProps = {
  title?: string;
  linkBack?: string;
  iconClickAction?: () => void;
};

export default observer(({ title = '', linkBack = 'back', iconClickAction, children }: PropsWithChildren<PageHeaderProps>) => {
  const history = useHistory();
  const { state } = useLocation<{ fromMenu?: boolean }>();
  const handleGoBack = () => {
    if (state && state.fromMenu) {
      history.push('/');
      mainStore.setIsVisibleMainMenu(true);
      return;
    }
    if (iconClickAction) {
      iconClickAction();
      return;
    }
    history.length === 1 ? history.push('/') : history.goBack();
  };

  return (
    <div className="page-header">
      {linkBack === 'back' ? (
        <div
          className="icon icon-arrow-back d-flex flex-center s-50 fs-20"
          onClick={handleGoBack}
        />
      ) : (
        <Link
          className="icon icon-arrow-back d-flex flex-center s-50 fs-20"
          to={{ pathname: linkBack, state: { isBackwarding: true } }}
        />
      )}
      <h3 className="page-header__title text-truncate">{title}</h3>
      {children ?? <div className="pr-50 mr-7" />}
    </div>
  );
});
