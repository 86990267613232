import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { uuid } from 'lodash-uuid';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import {
  ETARequests,
  ETACalculation,
  ETACost,
  ETADeliveryMethodType,
  ETAPaymentMethod,
} from '../api/ETA';
import {
  FillFeedbackRequest,
  NewOrderCartItem,
  NewOrderResponse,
  Order,
  OrderRequests,
  OrderShort,
  Payment,
  OrderPublicStatus,
  GiftItem,
  GiftMechanism,
  OrdersResponse,
  PaymentSystem,
  OrderStatus,
} from '../api/Order';
import {
  OrderData,
  checkoutStore,
  MobilePaymentMethod,
  AdditionalPaymentMethod,
} from './CheckoutStore';
import { userStore } from './UserStore';
import { mainStore } from './MainStore';
import { catalogStore } from './CatalogStore';
import { AxiosError } from 'axios';
import { CURRENCY_SYMBOL } from '../config';
import { ApiErrorResponse } from '../api/Requests';

export type OrderStatusStore =
  | 'none'
  | 'accepted'
  | 'picking'
  | 'readyToShip'
  | 'readyForPickup'
  | 'inDelivery'
  | 'delivered'
  | 'failed'
  | 'cancelled';

export type RequestETAResponse = {
  oldPrice: string;
  newPrice: string;
}

export interface RateInfo {
  rating: number;
  deliveryRating?: number;
  productVarietyRating?: number;
  productQualityRating?: number;
  comment?: string;
}

const DEFAULT_FEES_COST: ETACost = {
  shipping: 0,
  threshold: 0,
  shippingPounds: '0',
  thresholdPounds: '0',
  serviceFee: 0,
  serviceFeeThreshold: 0,
  serviceFeeThresholdPounds: '0',
  serviceFeeShippingPounds: '0',
};

class OrderStore {
  etaCalculation: ETACalculation | null = null;
  orderStatus: OrderStatusStore = 'none';
  orderList: OrderShort[] | null = null;
  isFirstOrder: Nullable<boolean> = null;
  currentOrdersPage = 1;
  activeOrderID = '';
  activeOrderBonuses: number | null = null;
  lastRatedOrderID = '';
  activeOrderProductCount = 0;
  paymentCards: Payment[] = [];
  activeGift: GiftItem | null = null;
  gift: GiftMechanism | null = null;
  isAuthInCart = false;
  tipAmount = '';
  freezeETAExpired = 0;
  idempotencyKey = '';
  isRegularTips = false;
  regularTipsValue = '';
  isCalculateTips = false;
  currency = 'gbp';
  paymentSystem: PaymentSystem | undefined | null = undefined;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'OrderStore',
      properties: [
        'etaCalculation', 'paymentCards', 'activeGift', 'gift', 'lastRatedOrderID',
        'idempotencyKey', 'regularTipsValue', 'isRegularTips', 'currency',
      ],
      storage: window.localStorage,
    }).catch((error) => error && console.error(error));
  }

  get orderTipsValue(): string {
    if (checkoutStore.deliveryMethod === ETADeliveryMethodType.ClickAndCollect) {
      return '';
    }

    if (!this.isCalculateTips) {
      return '';
    }

    return this.regularTipsValue;
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  getWHCode(): string | null {
    return this.etaCalculation?.warehouse.code || null;
  }

  get fee(): ETACost {
    return orderStore.etaCalculation?.cost[checkoutStore.deliveryMethod] ?? DEFAULT_FEES_COST;
  }

  get cartPriceForGift(): string {
    if (!this.gift) return '';
    if (!this.gift.minimum_order_amount) return '';
    const diff =
      mainStore.convertPoundsToPence(this.gift.minimum_order_amount) -
      mainStore.convertPoundsToPence(catalogStore.finalPrice);
    return diff <= 0 ? '' : mainStore.convertPenceToPounds(diff);
  }

  get isStoreAvailable() {
    return this.etaCalculation?.warehouse.availability.availableNow;
  }

  get whCode(): string {
    return this.etaCalculation?.warehouse.code ?? '';
  }

  get isPickupAvailable(): boolean {
    return !!orderStore.etaCalculation?.warehouse.deliveryMethods.find(
      ({ type }) => type === ETADeliveryMethodType.ClickAndCollect);
  }

  get currencySymbol(): string {
    return CURRENCY_SYMBOL[this.currency] || '';
  }

  // Setters
  setIsFirstOrder(flag: boolean) {
    this.isFirstOrder = flag;
  }

  setEtaCalculation(val: ETACalculation | null) {
    this.etaCalculation = val;
  }

  setOrderStatus(val: OrderPublicStatus | 'none') {
    let status: OrderStatusStore;
    switch (val) {
      case 'in_delivery':
        status = 'inDelivery';
        break;
      case 'ready_to_ship':
        status = 'readyToShip';
        break;
      case 'ready_for_pickup':
        status = 'readyForPickup';
        break;
      default:
        status = val;
        break;
    }
    this.orderStatus = status;
  }

  setActiveOrderID(val: string) {
    this.activeOrderID = val;
  }

  setActiveOrderBonuses(val: number) {
    this.activeOrderBonuses = val;
  }

  setLastRatedOrderID(val: string) {
    this.lastRatedOrderID = val;
  }

  setActiveOrderProductCount(val: number) {
    this.activeOrderProductCount = val;
  }

  setPaymentCards(arr: Payment[]) {
    this.paymentCards = arr;
  }

  setGift(val: GiftMechanism | null) {
    this.gift = val;
  }

  setActiveGift(val: GiftItem | null) {
    this.activeGift = val;
  }

  setIsAuthInCart(flag: boolean) {
    this.isAuthInCart = flag;
  }

  setCurrentOrdersPage(val: number) {
    this.currentOrdersPage = val;
  }

  setTipAmount(val: string) {
    this.tipAmount = val;
  }

  setFreezeETAExpired(val: number) {
    this.freezeETAExpired = val;
  }

  setCurrency(val: string) {
    this.currency = (val || 'gbp').toLowerCase();
  }

  setPaymentSystem(val: PaymentSystem) {
    this.paymentSystem = val || null;
  }

  // Actions
  getOrderById(id: string): OrderShort | null {
    if (!this.orderList) return null;
    for (let i = 0; i < this.orderList.length; i++) {
      if (this.orderList[i].id === id) return this.orderList[i];
    }
    return null;
  }

  calcOrderItems(order: OrderShort): OrderShort {
    const itemsCount = {
      actual: 0,
      requested: 0,
    };
    for (let i = 0; i < order.items.length; i++) {
      itemsCount.actual += order.items[i].actual_quantity ?? order.items[i].requested_quantity;
      itemsCount.requested += order.items[i].requested_quantity;
    }
    order.itemsCount = itemsCount;
    return order;
  }

  // Requests API
  fetchETA(pos: string): Promise<ETACalculation | null> {
    return ETARequests.getETA({
      pos: pos,
      seller: 'JIFFY',
    }).then(({ data }) => {
      runInAction(() => {
        mainStore.sendToRN('sendTags', {
          warehouse_id: data.warehouse.id,
        });
      });
      const costs: ETACalculation['cost'] = {};
      const paymentMethods: ETACalculation['paymentMethods'] = {};
      for (let i = 0; i < data.warehouse.deliveryMethods.length; i++) {
        paymentMethods[data.warehouse.deliveryMethods[i].type] = data.warehouse.deliveryMethods[i].paymentMethods;
        if (costs[data.warehouse.deliveryMethods[i].type]) continue;
        const cost: ETACost = {
          ...data.warehouse.deliveryMethods[i].cost,
          shippingPounds: mainStore.convertPenceToPounds(
            data.warehouse.deliveryMethods[i].cost.shipping,
          ),
          thresholdPounds: mainStore.convertPenceToPounds(
            data.warehouse.deliveryMethods[i].cost.threshold,
          ),
          serviceFeeThresholdPounds: mainStore.convertPenceToPounds(
            data.warehouse.deliveryMethods[i].cost.serviceFeeThreshold,
          ),
          serviceFeeShippingPounds: mainStore.convertPenceToPounds(
            data.warehouse.deliveryMethods[i].cost.serviceFee,
          ),
        };
        if (!cost.shipping || cost.shipping < 0) {
          cost.shipping = 0;
          cost.shippingPounds = '0';
        }
        if (!cost.threshold || cost.threshold < 0) {
          cost.threshold = 0;
          cost.thresholdPounds = '0';
        }
        if (!cost.serviceFee || (cost.serviceFee || -1) < 0) {
          cost.serviceFee = 0;
          cost.serviceFeeShippingPounds = '0';
        }
        if (!cost.serviceFeeThreshold || (cost.serviceFeeThreshold || -1) < 0) {
          cost.serviceFeeThreshold = 0;
          cost.serviceFeeThresholdPounds = '0';
        }
        costs[data.warehouse.deliveryMethods[i].type] = { ...cost };
      }
      if (!Object.keys(costs).length) {
        return null;
      }
      data.warehouse.availability.opening = data.warehouse.availability.opening.split(':').slice(0, 2).join(':');
      data.warehouse.availability.closing = data.warehouse.availability.closing.split(':').slice(0, 2).join(':');
      const etaCalculation: ETACalculation = {
        cost: costs,
        paymentMethods,
        distance: data.distance,
        duration: {
          ...data.duration,
          range: `${data.duration.min} - ${data.duration.max}`,
        },
        highDemand: data.highDemand,
        meta: data.meta,
        warehouse: data.warehouse,
      };
      return etaCalculation;
    });
  }

  async updateOrdersAnalytics() {
    if (!userStore.personalData.id) return;
    try {
      const { orders, total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id,
        },
      });
      let successfulOrders: OrdersResponse;
      if (total_count) {
        successfulOrders = await OrderRequests.getOrders({
          filter: {
            customer_id: userStore.personalData.id,
            status: [
              'created',
              'reserved',
              'confirmed',
              'delivery_created',
              'wh_created',
              'picking',
              'ready_to_ship',
              'in_delivery',
              'delivered',
              'delivered_partially',
              'finished',
            ],
          },
        });
      }
      runInAction(() => {
        mainStore.analytics.totalSpent = mainStore.convertPenceToPounds(
          (successfulOrders?.orders || []).reduce((sum, order) => sum + (order.paid_total || 0), 0),
        );
        mainStore.analytics.totalOrders = total_count;
        mainStore.analytics.totalSuccessfulOrders = successfulOrders?.total_count || 0;
        if (orders.length) {
          mainStore.analytics.lastOrderCreatedAt = orders[0].created_at;
          mainStore.analytics.firstOrderCreatedAt = orders[orders.length - 1].created_at;
        }
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total spent': mainStore.analytics.totalSpent,
        });
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total orders': total_count,
        });
        if (orders.length) {
          mainStore.sendToRN('setUserProperties', {
            'Commerce: first order date': mainStore.analytics.firstOrderCreatedAt,
          });
        }
      });
    } catch (error) {
      error && console.error(error);
    }
  }

  requestETA(): Promise<RequestETAResponse> {
    if (!userStore.deliveryAddress) return Promise.reject();
    return ETARequests.getETA({
      pos: `${userStore.deliveryAddress.coordinates.lat},${userStore.deliveryAddress.coordinates.lng}`,
      seller: 'JIFFY',
    })
      .then(({ data }) => {
        const costs: ETACalculation['cost'] = {};
        const paymentMethods: ETACalculation['paymentMethods'] = {};
        for (let i = 0; i < data.warehouse.deliveryMethods.length; i++) {
          paymentMethods[data.warehouse.deliveryMethods[i].type] = data.warehouse.deliveryMethods[i].paymentMethods;
          if (costs[data.warehouse.deliveryMethods[i].type]) continue;
          const cost: ETACost = {
            ...data.warehouse.deliveryMethods[i].cost,
            shippingPounds: mainStore.convertPenceToPounds(
              data.warehouse.deliveryMethods[i].cost.shipping,
            ),
            thresholdPounds: mainStore.convertPenceToPounds(
              data.warehouse.deliveryMethods[i].cost.threshold,
            ),
            serviceFeeThresholdPounds: mainStore.convertPenceToPounds(
              data.warehouse.deliveryMethods[i].cost.serviceFeeThreshold,
            ),
            serviceFeeShippingPounds: mainStore.convertPenceToPounds(
              data.warehouse.deliveryMethods[i].cost.serviceFee,
            ),
          };
          if (!cost.shipping || cost.shipping < 0) {
            cost.shipping = 0;
            cost.shippingPounds = '0';
          }
          if (!cost.threshold || cost.threshold < 0) {
            cost.threshold = 0;
            cost.thresholdPounds = '0';
          }
          if (!cost.serviceFee || (cost.serviceFee || -1) < 0) {
            cost.serviceFee = 0;
            cost.serviceFeeShippingPounds = '0';
          }
          if (!cost.serviceFeeThreshold || (cost.serviceFeeThreshold || -1) < 0) {
            cost.serviceFeeThreshold = 0;
            cost.serviceFeeThresholdPounds = '0';
          }
          costs[data.warehouse.deliveryMethods[i].type] = { ...cost };
        }
        if (this.freezeETAExpired - Date.now() > 0) {
          if (this.etaCalculation) {
            this.setEtaCalculation({ ...this.etaCalculation, paymentMethods });
          }

          return Promise.resolve({
            oldPrice: this.etaCalculation?.cost[checkoutStore.deliveryMethod]?.shippingPounds || '0',
            newPrice: this.etaCalculation?.cost[checkoutStore.deliveryMethod]?.shippingPounds || '0',
          });
        }
        if (!Object.keys(costs).length) {
          return Promise.reject({});
        }
        data.warehouse.availability.opening = data.warehouse.availability.opening.split(':').slice(0, 2).join(':');
        data.warehouse.availability.closing = data.warehouse.availability.closing.split(':').slice(0, 2).join(':');
        const etaCalculation: ETACalculation = {
          cost: costs,
          paymentMethods,
          distance: data.distance,
          duration: {
            ...data.duration,
            range: `${data.duration.min} - ${data.duration.max}`,
          },
          highDemand: data.highDemand,
          meta: data.meta,
          warehouse: data.warehouse,
        };
        const output: RequestETAResponse = {
          oldPrice: this.etaCalculation?.cost[checkoutStore.deliveryMethod]?.shippingPounds || '0',
          newPrice: etaCalculation.cost[checkoutStore.deliveryMethod]?.shippingPounds || '0',
        };
        runInAction(() => {
          if (
            this.etaCalculation?.highDemand !== etaCalculation.highDemand ||
            this.etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.shipping !== etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.shipping ||
            this.etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.threshold !== etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.threshold ||
            this.etaCalculation.duration.range !== etaCalculation.duration.range
          ) {
            mainStore.sendAnalytics(['BI', 'analytics'], {
              name: 'General: eta changed',
              params: {
                eta_min: etaCalculation.duration.min || 0,
                eta_max: etaCalculation.duration.max || 0,
                delivery_fee: etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.shippingPounds || 0,
                threshold: etaCalculation.cost[ETADeliveryMethodType.JiffyDelivery]?.thresholdPounds || 0,
                is_surger: etaCalculation.highDemand || false,
              },
            });
          }
          this.setEtaCalculation(etaCalculation);
          checkoutStore.setDeliveryPrices(output);
          if (output.oldPrice !== output.newPrice) checkoutStore.setIsUpdatedDeliveryPopover(true);
          mainStore.sendToRN('sendTags', {
            warehouse_id: data.warehouse.id,
          });
          mainStore.sendToRN('setUserProperties', {
            'Address: warehouse code': data.warehouse.id,
          });
          if (etaCalculation.highDemand) {
            if (catalogStore.cart.length) {
              mainStore.sendToRN('sendTags', {
                high_eta_without_order: true,
              });
            } else mainStore.sendToRN('removeTag', 'high_eta_without_order');
          } else mainStore.sendToRN('removeTag', 'high_eta_without_order');
        });
        return Promise.resolve(output);
      })
      .catch((error) => {
        if (!error.response && (error.code === 'ECONNABORTED' || error.message === 'Network Error')) {
          return Promise.reject();
        }
        runInAction(() => {
          this.setEtaCalculation(null);
          mainStore.setIsNeedChangeAddressPopover(true);
        });
        return Promise.reject();
      });
  }

  async newOrder(data: OrderData): Promise<NewOrderResponse | null> {
    if (!data.phone) return null;

    /**
     * Code below provides bonuses info for backend
     * "paidBonuses", "promocodeDiscountAmount" are required fields for new order request
     * and they should be presented in each cart item
     * */
    const calculatedItemsMap: Record<string, Record<string, number>> = {};
    const calculatedItems = catalogStore.totalCartPrice.items;
    for (let calculatedItemIndex = 0; calculatedItemIndex < calculatedItems.length; calculatedItemIndex += 1) {
      const sku = calculatedItems[calculatedItemIndex].sku;
      calculatedItemsMap[sku] = {
        paidBonuses: calculatedItems[calculatedItemIndex].paid_bonuses,
        promocodeDiscountAmount: calculatedItems[calculatedItemIndex].promocode_discount_amount,
        paidPrice: calculatedItems[calculatedItemIndex].paid_price,
        discountAmount: calculatedItems[calculatedItemIndex].discount_amount,
        basePrice: calculatedItems[calculatedItemIndex].base_price,
      };
    }

    const products: NewOrderCartItem[] = catalogStore.cart.map((item) => {
      return {
        sku: item.sku,
        requested_quantity: item.count,
        weight: 0,
        product_id: item.id.toString(),
        name: item.name,
        image: item.previewImageThumb || 'none',
        price_per_unit: item.pricePerUnit,
        paid_bonuses: calculatedItemsMap[item.sku].paidBonuses ?? 0,
        promocode_discount_amount: calculatedItemsMap[item.sku].promocodeDiscountAmount ?? 0,
        paid_price: calculatedItemsMap[item.sku].paidPrice ?? 0,
        discount_amount: calculatedItemsMap[item.sku].discountAmount ?? 0,
        base_price: calculatedItemsMap[item.sku].basePrice ?? 0,
      };
    });
    const gift =
      this.gift && this.activeGift
        ? { cms_id: this.gift.cms_id, gift_id: this.activeGift.id }
        : undefined;
    const payment_method =
      Object.values(MobilePaymentMethod).includes(
        checkoutStore.activePaymentMethod as MobilePaymentMethod)
        ? ETAPaymentMethod.Card
        : checkoutStore.activePaymentMethod === AdditionalPaymentMethod.KNET || checkoutStore.activePaymentMethod === AdditionalPaymentMethod.VisaMastercard
          ? ETAPaymentMethod.Card
          : checkoutStore.activePaymentMethod as ETAPaymentMethod;
    const payment_method_id = checkoutStore.activePaymentMethod === AdditionalPaymentMethod.KNET
      ? 1
      : checkoutStore.activePaymentMethod === AdditionalPaymentMethod.VisaMastercard
        ? 2
        : undefined;
    const session_id = !payment_method_id && payment_method === ETAPaymentMethod.Card ? data.sessionId : undefined;

    try {
      if (!this.idempotencyKey) {
        this.idempotencyKey = uuid();
      }

      const response = await OrderRequests.newOrder({
        customer_id: userStore.personalData.id?.toString() || '',
        currency: this.currency,
        warehouse_code: this.etaCalculation?.warehouse.code || '',
        base_total: mainStore.convertPoundsToPence(catalogStore.totalCartPrice.base),
        promocode_discount: mainStore.convertPoundsToPence(
          catalogStore.totalCartPrice.promocodeDiscount,
        ),
        discount_total: mainStore.convertPoundsToPence(
          catalogStore.totalCartPrice.discount,
        ),
        paid_total: mainStore.convertPoundsToPence(
          catalogStore.finalPrice,
        ),
        delivery_price: catalogStore.totalCartPrice.totalDeliveryPricePence,
        service_fee: catalogStore.totalCartPrice.serviceFeePence,
        is_delivery_free: catalogStore.isFreeDelivery,
        save_card: data.saveCard,
        // user can use bonuses or promocode only
        promocode: checkoutStore.useBonuses ? null : (catalogStore.promocode.value || null),
        items: products,
        recipient: {
          full_name: data.fullName,
          email: data.email.trim(),
          phone: data.phone,
        },
        address: {
          city: userStore.deliveryAddress?.city || '',
          country_id: userStore.deliveryAddress?.country || '',
          region: userStore.deliveryAddress?.region || '',
          address_1: data.address,
          address_2: data.address2 || '',
          latitude: userStore.deliveryAddress?.coordinates.lat || null,
          longitude: userStore.deliveryAddress?.coordinates.lng || null,
          zip: userStore.deliveryAddress?.zip || '',
          comment: data.comment,
          instructions: data.instructions || null,
          notes: data.notes || '',
        },
        delivery_method: data.deliveryMethod,
        gift: gift,
        client_source: 'App',
        should_use_bonuses: checkoutStore.useBonuses,
        paid_bonuses: catalogStore.totalCartPrice.paidBonusesPence,
        tips_amount: mainStore.convertPoundsToPence(orderStore.orderTipsValue),
        payment_method,
        session_id,
        payment_method_id,
      }, this.idempotencyKey);

      runInAction(() => {
        this.idempotencyKey = '';
      });

      return response;
    } catch ({ response }) {
      mainStore.pushAlert('error', i18n.t('errors:unableCreateOrder'));
      if (!response) return null;
      Sentry.withScope((scope) => {
        scope.setExtras({
          context: 'newOrder',
          error: response,
        });
        Sentry.captureMessage('[Checkout] Failed create new order', 'warning');
      });
      const { data } = response;
      if (data?.errors?.length) {
        if (data.errors[0] && data.errors[0].toLowerCase() === 'gift out of stock') {
          mainStore.setIsShowGiftOutStockPopover(true);
          return null;
        }
        if (data.errors[0] === 'Promocode is not valid') {
          runInAction(() => {
            catalogStore.promocode.message =
              data.data?.payload?.err_code === 'DISCOUNT.COUPONS.ONLY_FIRST_ORDER_ALLOWED'
                ? i18n.t('cartPage:notFirstOrderPromocodeFreeDelivery')
                : i18n.t('cartPage:promocodeNotFoundText');
            catalogStore.promocode.coupon = null;
            catalogStore.promocode.errorType = 'error';
            catalogStore.promocode.success = false;
            mainStore.setIsInvalidPromocodePopover(true);
          });
          return null;
        }
      }
    }
    return null;
  }

  async checkOrderPayment(orderId: string): Promise<{
    status: OrderStatus | null;
    received_bonuses: number | null;
  }> {
    try {
      const { order: { status, received_bonuses } } = await OrderRequests.getOrder(orderId);
      return { status, received_bonuses };
    } catch ({ response }) {
      if (!response) return { status: null, received_bonuses: null };
      Sentry.withScope((scope) => {
        scope.setExtras({
          context: 'checkOrderPayment',
          error: response,
        });
        Sentry.captureMessage('[Checkout] Failed create new order', 'warning');
      });
    }
    return { status: null, received_bonuses: null };
  }

  async orderCancel(orderId: string) {
    try {
      await OrderRequests.orderCancel(orderId);
    } catch (e) {
      console.error(e);
    }
  }

  async orderIdempotencyCancel(): Promise<boolean> {
    if (!this.idempotencyKey || !userStore.isAuthorized) {
      return true;
    }

    try {
      await OrderRequests.orderIdempotencyCancel({ idempotency_key: this.idempotencyKey });
      runInAction(() => {
        this.idempotencyKey = '';
      });

      return true;
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  async requestOrders(page = 1): Promise<boolean> {
    if (!userStore.personalData.id) return false;
    try {
      const { orders, total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id,
        },
        options: {
          page,
        },
      });
      const totalPages = Math.ceil(total_count / 20);
      runInAction(() => {
        const newOrders = orders
          .filter((order) => order.cancel_reason !== 'CANCEL_BY_USER')
          .map((order) => this.calcOrderItems(order));
        if (page === 1) {
          this.orderList = newOrders;
          this.currentOrdersPage = 1;
        } else {
          if (totalPages >= page) {
            this.orderList = [...(this.orderList || []), ...newOrders];
          } else if (!this.orderList) {
            this.orderList = [];
          }
        }
      });
      return totalPages > page;
    } catch (error) {
      error && console.error(error);
    }
    return false;
  }

  async fetchOrders() {
    if (!userStore.personalData.id) return;
    try {
      const { orders, total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id,
        },
      });
      let successfulOrders: OrdersResponse;
      if (total_count) {
        successfulOrders = await OrderRequests.getOrders({
          filter: {
            customer_id: userStore.personalData.id,
            status: [
              'created',
              'reserved',
              'confirmed',
              'delivery_created',
              'wh_created',
              'picking',
              'ready_to_ship',
              'in_delivery',
              'delivered',
              'delivered_partially',
              'finished',
            ],
          },
        });
      }
      runInAction(() => {
        this.orderList = orders
          .filter((order) => order.cancel_reason !== 'CANCEL_BY_USER')
          .map((order) => this.calcOrderItems(order));
        mainStore.analytics.totalSpent = mainStore.convertPenceToPounds(
          (successfulOrders?.orders || []).reduce((sum, order) => sum + (order.paid_total || 0), 0),
        );
        mainStore.analytics.totalOrders = total_count;
        mainStore.analytics.totalSuccessfulOrders = successfulOrders?.total_count || 0;
        if (orders.length) {
          mainStore.analytics.lastOrderCreatedAt = orders[0].created_at;
          mainStore.analytics.firstOrderCreatedAt = orders[orders.length - 1].created_at;
        }
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total spent': mainStore.analytics.totalSpent,
        });
        mainStore.sendToRN('setUserProperties', {
          'Commerce: total orders': total_count,
        });
        if (orders.length) {
          mainStore.sendToRN('setUserProperties', {
            'Commerce: first order date': mainStore.analytics.firstOrderCreatedAt,
          });
        }
      });
    } catch (error) {
      error && console.error(error);
    }
  }

  async requestOrder(id: string): Promise<Order | OrderShort | null> {
    if (!id) return null;
    if (this.orderList && this.orderList.length) {
      const order = this.getOrderById(id);
      if (order && order.public_status === 'delivered' && order.has_feedback) return order;
    }
    try {
      const { order } = await OrderRequests.getOrder(id);
      return this.calcOrderItems(order);
    } catch (e) {
      await this.errorHandler(e, 'requestOrder');
    }
    return null;
  }

  async requestPayments(): Promise<boolean> {
    if (!userStore.isAuthorized) return false;
    try {
      const res = await OrderRequests.getPayments();
      this.setPaymentCards(res);
      return true;
    } catch (e) {
      await this.errorHandler(e, 'requestPayments');
    }
    return false;
  }

  createIntent(): Promise<Payment | AxiosError> {
    return OrderRequests.createIntent().catch((e) => this.errorHandler(e, 'createIntent'));
  }

  addPayment(id: string): Promise<Payment | AxiosError> {
    return OrderRequests.addPayment({ payment_method_id: id }).catch((e) =>
      this.errorHandler(e, 'addPayment'),
    );
  }

  deletePayment(id: string): Promise<Payment | AxiosError> {
    return OrderRequests.deletePayment({ payment_method_id: id }).catch((e) =>
      this.errorHandler(e, 'deletePayment'),
    );
  }

  setDefaultPayment(id: string): Promise<Payment | AxiosError> {
    return OrderRequests.setDefaultPayment({ payment_method_id: id }).catch((e) =>
      this.errorHandler(e, 'setDefaultPayment'),
    );
  }

  async addFeedback(orderId: string, data: RateInfo) {
    const ratingRate = data.rating;

    if (!ratingRate) {
      return;
    }

    const feedBackID = await this.newFeedback(orderId, ratingRate);

    if (feedBackID && Object.keys(data).length > 1) {
      return this.fillFeedback(orderId, feedBackID, {
        rating: ratingRate,
        delivery_rating: data.deliveryRating ?? -1,
        product_quality_rating: data.productQualityRating ?? -1,
        product_variety_rating: data.productVarietyRating ?? -1,
        comment: data.comment ?? '',
      });
    }

    return true;
  }

  async newFeedback(orderId: string, rating: number): Promise<string> {
    try {
      const { id } = await OrderRequests.newFeedback({
        rating: rating,
        order_id: orderId,
      });

      mainStore.sendToRN('analytics', {
        name: 'Rate: order general',
        params: {
          order_id: orderId,
          score: rating,
        },
      });

      mainStore.sendToRN('firebaseAnalytics', {
        name: 'rate_order_general',
        params: {
          order_id: orderId,
          score: rating,
        },
      });

      return id;
    } catch (e) {
      await this.errorHandler(e, 'newFeedback');
    }
    return '';
  }

  async fillFeedback(
    orderId: string,
    feedbackId: string,
    data: FillFeedbackRequest,
  ): Promise<boolean> {
    const { delivery_rating, product_variety_rating, product_quality_rating, comment, rating } =
      data;

    mainStore.sendToRN('sendTags', {
      last_order_feedback_score: rating,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order rating': rating,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order delivery rating': delivery_rating,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order product variety rating': product_variety_rating,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order product quality rating': product_quality_rating,
    });
    mainStore.sendToRN('analytics', {
      name: 'Rate: order detailed',
      params: {
        order_id: orderId,
        delivery: delivery_rating,
        product_variety: product_variety_rating,
        product_quality: product_quality_rating,
        comment_provided: !!comment.length,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'rate_order_detailed',
      params: {
        order_id: orderId,
        delivery: delivery_rating,
        product_variety: product_variety_rating,
        product_quality: product_quality_rating,
        comment_provided: !!comment.length,
      },
    });

    try {
      await OrderRequests.fillFeedback(feedbackId, data);

      return true;
    } catch (e) {
      await this.errorHandler(e, 'fillFeedback');
    }

    return false;
  }

  async fetchGifts(): Promise<boolean> {
    try {
      const { data } = await OrderRequests.getGifts(this.etaCalculation?.warehouse.code || '');
      if (!data) {
        this.setGift(null);
        if (this.activeGift) {
          mainStore.setIsShowGiftOutStockPopover(true);
          return false;
        }
        return true;
      }
      const isMinimumOrderAmountDiff = !!(
        this.gift && this.gift.minimum_order_amount !== data.minimum_order_amount
      );
      this.setGift(data);
      this.setIsAuthInCart(false);
      if (this.activeGift) {
        if (
          (isMinimumOrderAmountDiff &&
            mainStore.convertPoundsToPence(catalogStore.finalPrice) <
            mainStore.convertPoundsToPence(data.minimum_order_amount)) ||
          !data.gift_items.some((item) => item.id === this.activeGift?.id)
        ) {
          mainStore.setIsShowGiftOutStockPopover(true);
          return false;
        }
      }
    } catch ({ response }) {
      if (!response) return false;
      if (
        this.isAuthInCart &&
        response.data?.errors?.length &&
        response.data.errors[0] &&
        response.data.errors[0].toLowerCase() === 'gift already received'
      ) {
        mainStore.setIsShowGiftAlreadyReceivedPopover(true);
      }
      this.setGift(null);
      if (this.activeGift) {
        mainStore.setIsShowGiftOutStockPopover(true);
        return false;
      }
    }
    return true;
  }

  async payTip(orderId: string, payAmount: string, isSaveCard: boolean): Promise<string> {
    try {
      const { client_secret } = await OrderRequests.payTip(orderId, {
        amount: mainStore.convertPoundsToPence(payAmount),
        save_card: isSaveCard,
        currency: this.currency,
        email: userStore.personalData.email || '',
      });
      return client_secret || '';
    } catch (error) {
      error && console.error(error);
    }
    return '';
  }

  async checkPayedTip(orderId: string): Promise<boolean> {
    if (!userStore.isAuthorized) return true;
    try {
      const { status } = await OrderRequests.checkPayedTip(orderId);
      return status === 'received';
    } catch (error) {
      if (error.response?.status === 400) return false;
      error && console.error(error);
    }
    return true;
  }

  getDeliveryCost(): Promise<RequestETAResponse> {
    if (!userStore.isAuthorized) return Promise.reject();
    const deliveryType = {
      [ETADeliveryMethodType.ClickAndCollect]: 1,
      [ETADeliveryMethodType.JiffyDelivery]: 2,
    };
    return ETARequests.getDeliveryCost({
      wh_code: this.etaCalculation?.warehouse.code || '',
      latitude: userStore.deliveryAddress?.coordinates.lat || '',
      longitude: userStore.deliveryAddress?.coordinates.lng || '',
      seller: 'jiffy',
      force: true,
      delivery_type: deliveryType[checkoutStore.deliveryMethod],
    })
      .then((e) => {
        const output: RequestETAResponse = {
          oldPrice: this.etaCalculation?.cost[checkoutStore.deliveryMethod]?.shippingPounds || '0',
          newPrice: mainStore.convertPenceToPounds(e.shipping),
        };
        runInAction(() => {
          if (!this.etaCalculation) return;
          this.setFreezeETAExpired(new Date(e.ttl).getTime());
          this.etaCalculation.cost[checkoutStore.deliveryMethod] = {
            ...this.etaCalculation.cost[checkoutStore.deliveryMethod],
            ...e,
            shippingPounds: mainStore.convertPenceToPounds(e.shipping),
            thresholdPounds: mainStore.convertPenceToPounds(e.threshold),
          } as ETACost;
          checkoutStore.setDeliveryPrices(output);
          if (output.oldPrice !== output.newPrice) checkoutStore.setIsUpdatedDeliveryPopover(true);
        });
        return Promise.resolve(output);
      })
      .catch((error) => {
        runInAction(() => {
          this.setFreezeETAExpired(0);
        });
        error && console.error(error);
        return Promise.reject();
      });
  }

  async checkIsFirstOrder(isForce?: boolean) {
    if (this.isFirstOrder !== null && !isForce) {
      return;
    }

    if (!userStore.personalData.id) {
      this.setIsFirstOrder(true);

      return;
    }

    try {
      // gets all statuses exclude canceled, delivery_failed, because such statuses are ignored on backend
      const { total_count } = await OrderRequests.getOrders({
        filter: {
          customer_id: userStore.personalData.id ?? '',
          status: [
            'created',
            'reserved',
            'confirmed',
            'delivery_created',
            'wh_created',
            'picking',
            'ready_to_ship',
            'in_delivery',
            'delivered',
            'delivered_partially',
            'finished',
          ],
        },
        options: {
          page: 1,
          limit: 1,
        },
      });

      this.setIsFirstOrder(total_count === 0);
      return;
    } catch (e) {
      this.setIsFirstOrder(true);
    }
  }

  requestCurrency() {
    OrderRequests.getCurrency().then(({ data: { currency = '', digits_after_coma = 2 } }) => {
      this.setCurrency(currency);
      mainStore.setDigitsAfterComma(digits_after_coma);
    }).catch((e) => this.errorHandler(e, 'requestCurrency'));
  }

  requestPriorityPaymentSystem() {
    if (!userStore.isAuthorized) return;

    OrderRequests.getPriorityPaymentSystem().then((system) => {
      this.setPaymentSystem(system);
    }).catch((e) => this.errorHandler(e, 'priorityPaymentSystem'));
  }

  // Errors
  errorHandler = (error: AxiosError<ApiErrorResponse>, context: string): Promise<AxiosError> =>
    mainStore.errorHandler(error, context);
}

export const orderStore = new OrderStore();
