/**
 * @prettier
 */

import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { mainStore } from '../stores/MainStore';
import { userStore } from '../stores/UserStore';
import SearchDummy from './SearchDummy';
import Icon from './ui/Icon/Icon';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ isBackwarding?: boolean }>();
  const [isActive, setIsActive] = useState(
    (history.action === 'POP' || state?.isBackwarding) &&
      (mainStore.pageScrollY['main'] ?? 0) > 5,
  );
  const [isShowSearch, setIsShowSearch] = useState(
    (history.action === 'POP' || state?.isBackwarding) &&
      (mainStore.pageScrollY['main'] ?? 0) > 160,
  );
  const onScroll = useCallback((e: Event) => {
    const currentTarget = e.currentTarget as HTMLElement | null;
    if (!currentTarget) {
      return;
    }
    setIsActive(currentTarget.scrollTop > 5);
    setIsShowSearch(currentTarget.scrollTop > 100);
  }, []);

  const handleMenu = () => {
    mainStore.setIsVisibleMainMenu(true);
    mainStore.setIsShowMainMenu(true);
    mainStore.setLoyaltyProgramSeen(true);
  };

  const handleChangeAddress = () => {
    if (userStore.isAuthorized) {
      mainStore.setIsChangeAddressPopover(true);
    } else {
      history.push({
        pathname: '/delivery-address',
        state: { isShowBackBtn: true, source: 'homepage' },
      });
    }
  };

  useEffect(() => {
    const elLayout = document.querySelector<HTMLElement>('.scroll-layout');
    if (!elLayout) {
      return;
    }
    elLayout.addEventListener('scroll', onScroll);
    return () => elLayout.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const showLoyaltyProgramDot =
    !mainStore.loyaltyProgramSeen &&
    userStore.isAuthorized &&
    !company.isDisableLoyaltyProgram;

  return (
    <header
      className={htmlClasses(
        'main-header ff-feixen-book d-flex flex-shrink-0 justify-content-between position-relative pl-3 pr-5',
        { _active: isActive },
      )}
    >
      <div className="d-flex align-items-center flex-shrink-0 position-relative w-100p justify-content_between">
        <div
          className={htmlClasses('icon icon-burger d-flex flex-center mx-16', {
            'main-header__dot': showLoyaltyProgramDot,
          })}
          onClick={handleMenu}
        >
          <div className="dash" />
        </div>

        <div
          className={htmlClasses(
            'main-header__zip w-100p fs-14 flex-start overflow-hidden',
            { 'd-none': isShowSearch },
          )}
          onClick={handleChangeAddress}
        >
          <div className="mx-5 overflow-hidden text-overflow-ellipsis">
            {company.config.useGoogleApiForGeocoding
              ? userStore.deliveryAddress?.zip
              : userStore.deliveryAddress?.shortAddress ?? t('notChosen')}
          </div>
          <span className="icon icon-chevron-right fs-10 s-16" />
        </div>

        <SearchDummy
          className={htmlClasses('flex-center fs-18 w-100p', {
            'd-none': !isShowSearch,
          })}
        />

        <div className="d-flex align-items-center">
          <Link
            className="d-flex flex-center s-42 fs-20 position-relative"
            to="/favorites"
          >
            <Icon type="heart" />
          </Link>
        </div>
      </div>
    </header>
  );
});
