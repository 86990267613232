import i18n from 'i18next';
import { IObservableArray, makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import {
  CatalogRequests,
  Banner,
  SearchCatsResponse,
  ProductRequest,
  RecommendProductsSource,
  CartCalculationItemResponse,
  CartCalculationItemRequest,
  ProductCommodityGroupAPI,
} from '../api/Catalog';
import { FavoritesRequest } from '../api/Favorites';
import { OrderRequests, CheckPromocodeResponse, GiftItem } from '../api/Order';
import {
  CATEGORIES_CACHE_DURATION,
  CATEGORY_CACHE_DURATION,
  PRODUCT_CACHE_DURATION,
  BANNERS_CACHE_DURATION,
  PRESENT_PROMOCODES,
  PRESENT_PROMOCODES_DATA,
  PURCHASED_ITEMS_COUNT,
  PRESENT_PROMOCODES_ORDERS_LIMIT,
} from '../config';
import { mainStore } from './MainStore';
import { userStore } from './UserStore';
import { orderStore } from './OrderStore';
import { checkoutStore } from './CheckoutStore';
import { AxiosError } from 'axios';
import debounce from 'lodash.debounce';
import { PICKUP_ALCOHOL_COUPON_CODE } from './constants';
import { Product, ProductOffer } from '../types/Product/interface';
import { calcProductOfferFields, convertProduct } from '../types/Product';
import { convertCategoryList } from '../types/Category';
import { Category } from '../types/Category/interface';
import { ETADeliveryMethodType } from '../api/ETA';
import { ApiErrorResponse } from '../api/Requests';
import { company } from '../company/Company';

export interface CartItem extends ProductOffer {
  count: number;
}

export interface Promocode {
  coupon: CheckPromocodeResponse | null;
  value: string;
  errorType: 'error' | 'warning' | null;
  message: string;
  success: boolean | null;
  discountLimit: string | null;
  deliveryMethod?: ETADeliveryMethodType;
}

type ProposedPromocode = {
  name: string;
  discount: string;
  title: string;
  text: string;
};

type TotalCartPrice = {
  base: string;
  discount: string;
  paid: string;
  paidWithDiscount: string;
  serviceFeePence: number;
  promocodeDiscount: string;
  amountLeftToDiscount: string;
  baseEarnedBonusesPence: number;
  earnedBonusesPence: number;
  isFirstOrder: boolean;
  isFreeDelivery: boolean;
  bonusesToSpentPounds: number;
  paidBonusesPence: number;
  totalDeliveryPricePence: number;
  items: CartCalculationItemResponse[];
  taxPercent: number,
  taxAmount: string,
};

type FirebaseCartItem = {
  item_id: string;
  item_name: string;
  quantity: number;
  promotion_id: string;
  promotion_name: string;
  affiliation: string;
  coupon: string;
  creative_name: string;
  creative_slot: string;
  discount: number;
  index: number;
  item_brand: string;
  item_category: string;
  item_list_name: string;
  item_list_id: string;
  item_variant: string;
  location_id: string;
  tax: number;
  price: number;
  currency: string;
};

type BannerList = {
  items: Banner[];
  displayType: 'tiles' | 'slider';
};

type CalculationProcess = {
  isLoading: boolean;
  isError: boolean;
  requestId: number;
}

type ProductListValue = {
  categoryName: string;
  subcategory: Category[];
  subcategoryWithDiscount: Category[];
}

type BannerProductListValue = {
  categoryName: string;
  products: Product[];
}

export class CatalogStore {
  calculationProcess: CalculationProcess = {
    isLoading: false,
    isError: false,
    requestId: 0,
  };
  categoryList: Category[] | null = null;
  categoryListCacheExpired: number | null = null;
  bannerList: BannerList | null = null;
  bannerListCacheExpired: number | null = null;
  productList: Record<string, ProductListValue> = {};
  productListCacheExpired: Record<string, number> = {};
  bannerProductList: Record<string, BannerProductListValue> = {};
  bannerProductListCacheExpired: Record<string, number> = {};
  products: Record<string, Product> = {};
  productsCacheExpired: Record<string, number> = {};
  bestsellersProducts: Product[] = [];
  bestsellersProductsCacheExpired: number | null = null;
  cart: CartItem[] = [];
  purchasedItems = new Map<number, Product>();
  purchasedItemsPage = 0;
  favorites: Record<string, Product> = {};
  recommendItems: Product[] = [];
  searchHistory: string[] = [];
  searchQuery = '';
  searchProductList: Product[] = [];
  searchCatsList: SearchCatsResponse[] = [];
  searchIdeasList: string[] = [];
  searchSuggestionsList: string[] = [];
  outStockProductList: CartItem[] = [];
  changePriceProductList: CartItem[] = [];
  changeCountProductList: CartItem[] = [];
  adultProductList: CartItem[] = [];
  promocode: Promocode = {
    coupon: null,
    value: '',
    errorType: null,
    message: '',
    success: null,
    discountLimit: null,
  };
  addToCartCatch: { productOffer: ProductOffer; count: number; source: string } | null = null;
  addToGiftCatch: { gift: GiftItem, callback: () => void } | null = null;
  productsOutOfStockExpandedList: string[] = [];
  usedPromocodes: Record<string, string[]> = {};
  proposedPromocodes: ProposedPromocode[] = [];
  totalCartPrice: TotalCartPrice = {
    amountLeftToDiscount: '',
    base: '',
    discount: '',
    paid: '',
    paidWithDiscount: '',
    promocodeDiscount: '',
    earnedBonusesPence: 0,
    bonusesToSpentPounds: 0,
    baseEarnedBonusesPence: 0,
    serviceFeePence: 0,
    totalDeliveryPricePence: 0,
    paidBonusesPence: 0,
    isFirstOrder: true,
    isFreeDelivery: false,
    items: [],
    taxPercent: 0,
    taxAmount: '',
  };

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CatalogStore',
      properties: ['cart', 'favorites', 'promocode', 'searchHistory', 'usedPromocodes'],
      storage: window.localStorage,
    }).catch((error) => error && console.error(error));
    i18n.on('initialized', () => {
      runInAction(() => {
        this.setSearchSuggestionsList(i18n.t('searchPage:ideasList', { returnObjects: true }));
        this.setSearchIdeasList(i18n.t('searchPage:ideasList', { returnObjects: true }));
      });
    });
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get isRestrictedItemInCart(): boolean {
    const commodityGroupList: ProductCommodityGroupAPI[] = [ProductCommodityGroupAPI.TOBACCO];

    if (company.isExceptionAlcohol) commodityGroupList.push(ProductCommodityGroupAPI.ALCOHOL);

    for (let i = 0; i < this.cart.length; i++) {
      if (commodityGroupList.includes(this.cart[i].commodityGroup)) {
        return true;
      }
    }

    return false;
  }

  get isAdultItemInCart(): boolean {
    for (let i = 0; i < this.cart.length; i++) {
      if (this.cart[i].properties.age_restriction) return true;
    }
    return false;
  }

  get isCharityPromocode(): boolean {
    return false;
  }

  get totalCartCount(): number {
    let totalCount = 0;
    for (let i = 0; i < this.cart.length; i++) {
      totalCount += this.cart[i].count;
    }
    return totalCount;
  }

  get leftUntilFreeDelivery(): [string, string] {
    const zero: [string, string] = ['0', '100%'];
    if (userStore.personalData.freeDeliveryDaysLeft > 0) {
      return zero;
    }

    return this.leftUntilFreeFee(
      mainStore.convertPoundsToPence(this.totalCartPrice.paidWithDiscount),
      orderStore.fee.threshold || 0,
    );
  }

  get leftUntilFreeServiceFee(): [string, string] {
    return this.leftUntilFreeFee(
      mainStore.convertPoundsToPence(this.totalCartPrice.paidWithDiscount),
      orderStore.fee.serviceFeeThreshold ?? 0,
    );
  }

  get leftUntilFreeMinimalOrderFee(): [string, string] {
    const zero: [string, string] = ['0', '100%'];
    if (userStore.isStaff) {
      return zero;
    }

    if (orderStore.isFirstOrder) {
      return zero;
    }

    const paid = mainStore.convertPoundsToPence(this.totalCartPrice.paidWithDiscount);

    return this.leftUntilFreeFee(paid, company.config.minimalOrderFeeThreshold);
  }

  get finalPrice() {
    return this.totalCartPrice.paid || '0';
  }

  get isFreeDelivery(): boolean {
    if (this.totalCartPrice.isFreeDelivery) return true;
    if (userStore.personalData.freeDeliveryDaysLeft > 0) return true;
    if (checkoutStore.deliveryMethod === ETADeliveryMethodType.ClickAndCollect) return true;
    return this.cart.length > 0 && mainStore.isZero(this.leftUntilFreeDelivery[0]);
  }

  get isFreeServiceFee(): boolean {
    if (orderStore.fee.serviceFee === 0) return true;
    if (!orderStore.fee.serviceFeeThreshold) return true;
    return this.cart.length > 0 && mainStore.isZero(this.leftUntilFreeServiceFee[0]);
  }

  get favoritesList(): Product[] {
    const favoritesKeys = Object.keys(this.favorites);
    if (!favoritesKeys.length) return [];
    return Object.values(this.favorites);
  }

  get purchasedItemsList(): Product[] {
    if (!this.purchasedItems.size) return [];
    return Array.from(this.purchasedItems, ([, value]) => value);
  }

  get cartForFirebase(): FirebaseCartItem[] {
    return this.cart.map((item, i): FirebaseCartItem => {
      return {
        item_id: item.sku,
        item_name: item.name,
        quantity: item.count,
        promotion_id: '',
        promotion_name: '',
        affiliation: '',
        coupon: this.promocode.value,
        creative_name: '',
        creative_slot: '',
        discount: item.discountPrice
          ? mainStore.toFloat(mainStore.convertPenceToPounds(item.price - item.discountPrice))
          : 0,
        index: i,
        item_brand: '',
        item_category: item.categoryName,
        item_list_name: item.categoryName,
        item_list_id: item.categoryId.toString(),
        item_variant: '',
        location_id: '',
        tax: 0,
        price: mainStore.toFloat(item.priceFormatted),
        currency: orderStore.currency.toUpperCase(),
      };
    });
  }

  get isPromoCodeApplied() {
    return (
      this.promocode.success &&
      parseFloat(this.totalCartPrice.promocodeDiscount) > 0 &&
      mainStore.isZero(this.totalCartPrice.amountLeftToDiscount)
    );
  }

  get isFixedPromoCode() {
    return this.promocode.coupon?.type === 'FIXED';
  }

  get isStoreInMinimalOrderList() {
    if (!orderStore.etaCalculation?.warehouse?.code) {
      return false;
    }

    return company.config.warehouse.minimalOrderFeeWarehouseCodes.includes(
      orderStore.etaCalculation.warehouse?.code);
  }

  get isMinimalOrderFeePassed() {
    if (userStore.isStaff) {
      return true;
    }

    if (orderStore.isFirstOrder) {
      return true;
    }

    if (!this.isStoreInMinimalOrderList) {
      return true;
    }

    if (this.cart.length === 0) {
      return false;
    }

    return mainStore.isZero(this.leftUntilFreeMinimalOrderFee[0]);
  }

  get formatPromocodeDiscountAmount() {
    const amount = this.promocode.coupon?.value || 0;

    if (mainStore.isZero(amount)) return '0';

    if (this.promocode.coupon?.type === 'PERCENTAGE') return `${amount}%`;

    return mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(amount));
  }

  // Setters
  setSearchProductList(products: Product[]) {
    this.searchProductList = products;
  }

  setSearchCatsList(categories: SearchCatsResponse[]) {
    this.searchCatsList = categories;
  }

  setCartItemCountByProduct(
    productOffer: ProductOffer,
    count: number,
    action: 'add' | 'remove',
    source: string,
    lvl3category?: number,
  ) {
    if (count < 0) count = 0;
    if (
      productOffer.properties.age_restriction &&
      !userStore.personalData.isAdult &&
      action === 'add' &&
      count
    ) {
      this.addToCartCatch = { productOffer, count, source };
      mainStore.setIsAgeRestrictionPopover(true);
      return;
    }
    if (action === 'add' && productOffer.promoRequiredQuantity > count) {
      mainStore.pushAlert(
        'warning',
        i18n.t('addMoreToDiscount', { count: productOffer.promoRequiredQuantity - count }),
      );
    }
    let isNewProduct = true;
    for (let i = 0; i < this.cart.length; i++) {
      if (this.cart[i].id === productOffer.id) {
        if (!count) {
          this.cart.splice(i, 1);
          if (!this.cart.length) this.resetPromocode();
        } else {
          this.cart[i].count = count;
          (this.cart as IObservableArray<CartItem>).replace(this.cart);
        }
        isNewProduct = false;
      }
    }
    if (count && isNewProduct) {
      this.cart.push({ ...productOffer, count: count });
      mainStore.sendToRN('sendTags', {
        cart_product_name: productOffer.name,
      });
      mainStore.sendToRN('sendTags', {
        cart_product_price:
          productOffer.discountPrice
            ? productOffer.discountPriceFormatted
            : productOffer.priceFormatted,
      });
      mainStore.sendToRN('sendTags', {
        cart_product_image: productOffer.previewImageThumb,
      });
    }
    mainStore.sendToRN('sendTags', {
      cart_update_time: Math.floor(Date.now() / 1000),
    });
    const analyticEventName =
      action === 'add' ? 'Purchase: product added to cart' : 'Purchase: product removed from cart';
    const firebaseEventName = action === 'add' ? 'add_to_cart' : 'remove_from_cart';
    const productsAmount = this.cart.reduce((sum, item) => sum + item.count, 0);
    mainStore.sendAnalytics(['BI', 'analytics', 'yaMetrika'], {
      name: analyticEventName,
      params: {
        product_id: productOffer.id,
        category_id: lvl3category || productOffer.categoryId,
        lvl1_category_id: undefined,
        lvl2_category_id: productOffer.parentCategoryId,
        source: source,
        quantity: count,
        cart_id: undefined,
        products_amount: productsAmount,
        items_amount: this.cart.length,
        price: this.totalCartPrice.base,
        final_price: this.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: firebaseEventName,
      params: {
        currency: orderStore.currency.toUpperCase(),
        items: [
          {
            item_id: productOffer.sku,
            item_name: productOffer.name,
            quantity: 1,
            promotion_id: '',
            promotion_name: '',
            affiliation: '',
            coupon: this.promocode.value,
            creative_name: '',
            creative_slot: '',
            discount: productOffer.discountPrice
              ? mainStore.toFloat(
                mainStore.convertPenceToPounds(productOffer.price - productOffer.discountPrice),
              )
              : 0,
            index: 0,
            item_brand: '',
            item_category: productOffer.categoryName,
            item_list_name: productOffer.categoryName,
            item_list_id: productOffer.categoryId,
            item_variant: '',
            location_id: '',
            tax: 0,
            price: mainStore.toFloat(productOffer.priceFormatted),
            currency: orderStore.currency.toUpperCase(),
          },
        ],
        value: mainStore.toFloat(productOffer.priceFormatted),
      },
    });
  }

  emptyCart() {
    this.cart = [];
    this.resetPromocode();
    mainStore.sendToRN('removeTag', 'cart_update_time');
    mainStore.sendToRN('removeTag', 'cart_product_name');
    mainStore.sendToRN('removeTag', 'cart_product_price');
    mainStore.sendToRN('removeTag', 'cart_product_image');
  }

  setSearchQuery(val: string) {
    this.searchQuery = val;
  }

  setCategoryList(val: Category[] | null) {
    this.categoryList = val;
  }

  setCategoryListCacheExpired(val: number | null) {
    this.categoryListCacheExpired = val;
  }

  setProductListCacheExpired(val: Record<string, number>) {
    this.productListCacheExpired = val;
  }

  setProductsCacheExpired(val: Record<string, number>) {
    this.productsCacheExpired = val;
  }

  toggleFavorite(product: Product, source: string) {
    if (this.favorites[product.id]) {
      if (userStore.isAuthorized) this.removeFavorite(product.id);
      delete this.favorites[product.id];
      return;
    }
    if (userStore.isAuthorized) this.addFavorite([{ product_id: product.id }]);
    this.favorites[product.id] = product;
    mainStore.sendToRN('analytics', {
      name: 'General: product added to favorites',
      params: {
        product_id: product.id,
        category_id: product.categoryId,
        lvl1_category_id: undefined,
        lvl2_category_id: product.parentCategoryId,
        source: source, // (search / category / homepage / product_main / product_reco / cart)
        price: product.priceFormatted,
        final_price: product.discountPrice ? product.discountPriceFormatted : product.priceFormatted,
      },
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: favourites number': Object.keys(this.favorites).length,
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'add_to_wishlist',
      params: {
        currency: orderStore.currency.toUpperCase(),
        items: [
          {
            item_id: undefined,
            item_name: product.name,
            quantity: 1,
            promotion_id: '',
            promotion_name: '',
            affiliation: '',
            coupon: this.promocode.value,
            creative_name: '',
            creative_slot: '',
            discount: product.discountPrice
              ? mainStore.toFloat(
                mainStore.convertPenceToPounds(product.discountPrice - product.price))
              : 0,
            index: 0,
            item_brand: '',
            item_category: product.categoryName,
            item_list_name: product.categoryName,
            item_list_id: product.categoryId,
            item_variant: '',
            location_id: '',
            tax: 0,
            price: mainStore.toFloat(product.priceFormatted),
            currency: orderStore.currency.toUpperCase(),
          },
        ],
        value: mainStore.toFloat(product.priceFormatted),
      },
    });
  }

  emptyOutStock() {
    if (this.outStockProductList.length || this.adultProductList.length) {
      for (let i = 0; i < this.outStockProductList.length; i++) {
        this.setCartItemCountByProduct(this.outStockProductList[i], 0, 'remove', 'out_of_stock');
      }
      for (let i = 0; i < this.adultProductList.length; i++) {
        this.setCartItemCountByProduct(this.adultProductList[i], 0, 'remove', 'out_of_stock');
      }
      mainStore.sendAnalytics(['BI', 'analytics', 'firebase'], {
        name: 'Purchase: cart changed',
        params: {
          cart_id: undefined,
          type: 'stock',
          products_amount: this.cart.reduce((sum, item) => sum + item.count, 0),
          items_amount: this.cart.length,
          price: this.totalCartPrice.base,
          final_price: this.finalPrice,
          eta_min: orderStore.etaCalculation?.duration.min || 0,
          eta_max: orderStore.etaCalculation?.duration.max || 0,
          delivery_fee: orderStore.fee.shippingPounds || 0,
          threshold: orderStore.fee.thresholdPounds || 0,
          is_surger: orderStore.etaCalculation?.highDemand || false,
          stage: 'checkout',
        },
      });
    }
    this.outStockProductList = [];
    this.adultProductList = [];
    this.changePriceProductList = [];
    this.changeCountProductList = [];
  }

  resetPromocode() {
    if (this.promocode.success === null) return;
    this.promocode = {
      coupon: null,
      value: '',
      errorType: null,
      message: '',
      success: null,
      discountLimit: null,
    };
  }

  updateSearchHistory(val: string[] | string) {
    if (Array.isArray(val)) {
      this.searchHistory = val;
    } else {
      if (this.searchHistory.length && this.searchHistory[0] === 'item') return;
      const searchHistory = this.searchHistory.filter((el) => el !== val);
      searchHistory.unshift(val);
      this.searchHistory = searchHistory.slice(0, 5);
    }
  }

  addProductsOutOfStockExpandedList(id: string) {
    this.productsOutOfStockExpandedList.push(id);
  }

  resetProductsOutOfStockExpandedList() {
    this.productsOutOfStockExpandedList = [];
  }

  addUsedPromocodes(promocode: string) {
    if (!userStore.isAuthorized || !userStore.personalData.id) return;
    promocode = promocode.toUpperCase();
    if (this.usedPromocodes[userStore.personalData.id]) {
      if (this.usedPromocodes[userStore.personalData.id].indexOf(promocode) !== -1) return;
      if (PRESENT_PROMOCODES.includes(promocode)) {
        this.usedPromocodes[userStore.personalData.id].push(promocode);
      } else {
        this.usedPromocodes[userStore.personalData.id].unshift(promocode);
      }
    } else {
      this.usedPromocodes[userStore.personalData.id] = [promocode];
    }
  }

  removeUsedPromocodes(promocode: string) {
    if (
      !userStore.isAuthorized ||
      !userStore.personalData.id ||
      !this.usedPromocodes[userStore.personalData.id]
    )
      return;
    promocode = promocode.toUpperCase();
    this.usedPromocodes[userStore.personalData.id] = this.usedPromocodes[
      userStore.personalData.id
      ].filter((item) => item !== promocode);
  }

  setSearchIdeasList(val: string[]) {
    this.searchIdeasList = val;
  }

  setSearchSuggestionsList(val: string[]) {
    this.searchSuggestionsList = val;
  }

  setCalculationProcess(val: CalculationProcess) {
    this.calculationProcess = val;
  }

  setProducts(product: Product) {
    this.products[product.id] = product;
    this.productsCacheExpired[product.id] = Date.now() + PRODUCT_CACHE_DURATION;
  }

  setBestsellersProducts(products: Product[]) {
    this.bestsellersProducts = products;
    this.bestsellersProductsCacheExpired = products.length ? Date.now() + CATEGORIES_CACHE_DURATION : null;
  }

  setProductList({
    subCat2Id,
    categoryName,
    subcategory,
    subcategoryWithDiscount,
  }: ProductListValue & { subCat2Id: string }) {
    this.productList[subCat2Id] = { categoryName, subcategory, subcategoryWithDiscount };
    this.productListCacheExpired[subCat2Id] = Date.now() + CATEGORY_CACHE_DURATION;
  }

  setBannerProductList({
    bannerId,
    categoryName,
    products,
  }: BannerProductListValue & { bannerId: string }) {
    this.bannerProductList[bannerId] = { categoryName, products };
    this.bannerProductListCacheExpired[bannerId] = Date.now() + CATEGORY_CACHE_DURATION;
  }

  setRecommendItems(products: Product[]) {
    this.recommendItems = products;
  }

  setFavorites(obj: Record<string, Product>) {
    this.favorites = obj;
  }

  // Actions
  getCartItemCountById(offerId: number): number {
    for (let i = 0; i < this.cart.length; i++) {
      if (this.cart[i].id === offerId) return this.cart[i].count;
    }
    return 0;
  }

  optimizeProductListCache() {
    const cacheKeys = Object.keys(this.productListCacheExpired);
    const dateNow = Date.now();
    for (let i = 0; i < cacheKeys.length; i++) {
      if (this.productListCacheExpired[cacheKeys[i]] <= dateNow) {
        delete this.productList[cacheKeys[i]];
        delete this.productListCacheExpired[cacheKeys[i]];
      }
    }
  }

  optimizeBannerProductListCache() {
    const cacheKeys = Object.keys(this.bannerProductListCacheExpired);
    const dateNow = Date.now();
    for (let i = 0; i < cacheKeys.length; i++) {
      if (this.bannerProductListCacheExpired[cacheKeys[i]] <= dateNow) {
        delete this.bannerProductList[cacheKeys[i]];
        delete this.bannerProductListCacheExpired[cacheKeys[i]];
      }
    }
  }

  optimizeProductsCache() {
    const cacheKeys = Object.keys(this.products);
    const dateNow = Date.now();
    for (let i = 0; i < cacheKeys.length; i++) {
      if (this.productsCacheExpired[cacheKeys[i]] <= dateNow) {
        delete this.products[cacheKeys[i]];
        delete this.productsCacheExpired[cacheKeys[i]];
      }
    }
  }

  getPrice(cartItem: CartItem): NumberInt {
    if (!cartItem.promoQuantityDiscountPrice || !cartItem.promoRequiredQuantity) {
      return cartItem.price;
    }
    return cartItem.count >= cartItem.promoRequiredQuantity
      ? cartItem.promoQuantityDiscountPrice
      : cartItem.price;
  }

  /**
   * @return
   * true - cart items changed
   *
   * false - cart items not changed
   * */
  updateCartItems(
    skuList: string[],
    cartObj: Record<string, CartItem>,
    items: CartCalculationItemResponse[],
  ): boolean {
    runInAction(() => {
      this.outStockProductList = [];
      this.changePriceProductList = [];
      this.changeCountProductList = [];
      if (!items.length) {
        this.outStockProductList = toJS(this.cart);
        return;
      }
      for (let i = 0; i < items.length; i++) {
        if (!cartObj[items[i].sku]) continue;
        skuList.splice(skuList.indexOf(items[i].sku), 1);
        if (cartObj[items[i].sku].count <= 0) {
          cartObj[items[i].sku].count = 0;
          this.outStockProductList.push(cartObj[items[i].sku]);
          continue;
        }
        if (items[i].sellable <= 0) {
          cartObj[items[i].sku].count = 0;
          this.outStockProductList.push(cartObj[items[i].sku]);
          continue;
        }
        let isPriceChanged = false;
        let isCountChanged = false;
        let isOutStock = false;
        const originCartItem = toJS(cartObj[items[i].sku]);
        if (items[i].sellable !== undefined) {
          cartObj[items[i].sku].sellable = items[i].sellable;
        }
        const paidPrice = items[i].discount_price !== undefined ? items[i].discount_price : items[i].paid_price;
        if (items[i].discount_amount && cartObj[items[i].sku].discountPrice !== paidPrice) {
          cartObj[items[i].sku].discountPrice = paidPrice;
          if (paidPrice > cartObj[items[i].sku].discountPrice) {
            isPriceChanged = true;
          }
        }
        if (!items[i].discount_amount && cartObj[items[i].sku].discountPrice) {
          cartObj[items[i].sku].discountPrice = 0;
          isPriceChanged = true;
        }
        if (cartObj[items[i].sku].price !== items[i].base_price) {
          cartObj[items[i].sku].price = items[i].base_price;
          if (items[i].base_price > cartObj[items[i].sku].price) {
            isPriceChanged = true;
          }
        }
        if (cartObj[items[i].sku].count > items[i].sellable) {
          cartObj[items[i].sku].count = items[i].sellable;
          if (cartObj[items[i].sku].count <= 0) {
            cartObj[items[i].sku].count = 0;
            isOutStock = true;
          } else {
            isCountChanged = true;
          }
        }
        if (isPriceChanged) {
          this.changePriceProductList.push(originCartItem);
        }
        if (isCountChanged) {
          this.changeCountProductList.push(originCartItem);
        }
        if (isOutStock) {
          this.outStockProductList.push(originCartItem);
        }
      }
      for (let i = 0; i < skuList.length; i++) {
        cartObj[skuList[i]].count = 0;
        this.outStockProductList.push(cartObj[skuList[i]]);
      }
      this.cart = Object.values(cartObj).map((item) => calcProductOfferFields(item));
    });
    if (
      this.outStockProductList.length ||
      this.changePriceProductList.length ||
      this.changeCountProductList.length
    ) {
      mainStore.setIsShowOutStockPopover(true);
      return true;
    }
    return false;
  }

  collectAdultProducts() {
    for (let i = 0; i < this.cart.length; i++) {
      if (this.cart[i].properties.age_restriction) this.adultProductList.push(this.cart[i]);
    }

    if (this.adultProductList.length) mainStore.setIsShowOutStockPopover(true);
  }

  validatePromocode(data: CheckPromocodeResponse, promocodeName: string): Promocode {
    const promocode: Promocode = {
      coupon: null,
      value: '',
      errorType: null,
      message: '',
      success: null,
      discountLimit: null,
    };

    if (!data.isActive) {
      promocode.coupon = null;
      promocode.value = promocodeName;
      promocode.errorType = 'error';
      promocode.message = i18n.t('cartPage:promocodeNotFoundText');
      promocode.success = false;
      promocode.discountLimit = null;
      return promocode;
    }

    if (data.code?.toUpperCase() === PICKUP_ALCOHOL_COUPON_CODE) {
      if (!orderStore.isPickupAvailable) {
        promocode.coupon = null;
        promocode.value = promocodeName;
        promocode.errorType = 'error';
        promocode.message = i18n.t('cartPage:promocodeNotFoundText');
        promocode.success = false;
        promocode.discountLimit = null;
        return promocode;
      } else {
        promocode.deliveryMethod = ETADeliveryMethodType.ClickAndCollect;
      }
    } else {
      promocode.deliveryMethod = undefined;
    }

    promocode.coupon = data;
    promocode.value = promocodeName;
    promocode.errorType = null;
    promocode.message = '';
    promocode.success = true;
    promocode.discountLimit = null;
    if (
      promocode.coupon &&
      promocode.coupon.type === 'FIXED' &&
      promocode.coupon.value &&
      !promocode.coupon.minimumPurchase
    ) {
      promocode.coupon.minimumPurchase = promocode.coupon.value;
    }

    return promocode;
  }

  // Requests API
  requestCategories() {
    if (
      this.categoryList &&
      this.categoryList.length &&
      this.categoryListCacheExpired &&
      this.categoryListCacheExpired > Date.now()
    ) {
      return;
    }
    if (!orderStore.etaCalculation) {
      this.categoryList = [];
      this.categoryListCacheExpired = null;
      return;
    }
    CatalogRequests.getCategories({
      warehouseCode: orderStore.etaCalculation.warehouse.code,
      isSafeRequest: mainStore.isSafeRequest || undefined,
    })
      .then(({ data = [] }) => {
        this.setCategoryList(convertCategoryList(data));
        this.setCategoryListCacheExpired(Date.now() + CATEGORIES_CACHE_DURATION);
      })
      .catch((e) => {
        this.setCategoryList([]);
        this.setCategoryListCacheExpired(null);
        this.errorHandler(e, 'requestCategories').catch((error) => error && console.error(error));
      });
  }

  requestBanners() {
    if (
      this.bannerList &&
      this.bannerList.items.length &&
      this.bannerListCacheExpired &&
      this.bannerListCacheExpired > Date.now()
    ) {
      return;
    }
    if (!orderStore.etaCalculation) {
      this.bannerList = {
        items: [],
        displayType: 'tiles',
      };
      return;
    }
    CatalogRequests.getBanners({
      warehouse_code: orderStore.etaCalculation.warehouse.code,
    })
      .then((e) => {
        runInAction(() => {
          let displayType: 'tiles' | 'slider' = 'tiles';
          if (e.data.length > 1 && e.data.every((item) => item.tile_size === 3)) {
            displayType = 'slider';
          }
          this.bannerList = {
            items: e.data || [],
            displayType: displayType,
          };
          this.bannerListCacheExpired = Date.now() + BANNERS_CACHE_DURATION;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.bannerList = {
            items: [],
            displayType: 'tiles',
          };
          this.bannerListCacheExpired = null;
        });
        error && console.error(error);
      });
  }

  async requestProducts(subCat2Id: string, isDeeplink: boolean): Promise<ProductListValue | null> {
    this.optimizeProductListCache();
    if (!orderStore.etaCalculation || !subCat2Id) return null;
    if (
      !isDeeplink &&
      this.productList[subCat2Id] &&
      this.productListCacheExpired[subCat2Id] &&
      this.productListCacheExpired[subCat2Id] > Date.now()
    ) {
      return this.productList[subCat2Id];
    }
    const requestData: {
      warehouseCode: string;
      categoryId?: string;
      deepLink?: string;
      isSafeRequest?: boolean;
    } = {
      warehouseCode: orderStore.etaCalculation.warehouse.code,
      isSafeRequest: mainStore.isSafeRequest || undefined,
    };
    /*if (isDeeplink) requestData.deepLink = subCat2Id;
    else requestData.categoryId = subCat2Id;*/
    try {
      const { data } = await CatalogRequests.getProducts(subCat2Id, requestData);
      const output: ProductListValue = {
        categoryName: '',
        subcategory: [],
        subcategoryWithDiscount: [],
      };
      if (!data) {
        return output;
      }
      const category = convertCategoryList([data])[0];
      output.categoryName = category.name;
      if (category.subcategory.length) {
        const subcategories = category.subcategory.filter((category) => !!category.products.length);
        output.subcategory = subcategories;
        subcategories.forEach((subcategory) => {
          const productsWithDiscount: Product[] = [];
          subcategory.products.forEach((product) => {
            if (
              category.name !== 'Offers'
              && subcategory.name !== 'Bestsellers'
              && product.discountPrice
              && product.sellable
            ) {
              productsWithDiscount.push(product);
            }
          });
          if (productsWithDiscount.length) {
            output.subcategoryWithDiscount.push({
              ...subcategory,
              products: productsWithDiscount,
            });
          }
        });
      }
      this.setProductList({ ...output, subCat2Id });
      return output;
    } catch (e) {
      await this.errorHandler(e, 'requestProducts');
    }
    return null;
  }

  async requestBannerProducts(
    bannerId: string,
    isDeeplink: boolean,
  ): Promise<BannerProductListValue | null> {
    this.optimizeBannerProductListCache();
    if (!orderStore.etaCalculation || !bannerId) return null;
    if (
      this.bannerProductList[bannerId] &&
      this.bannerProductListCacheExpired[bannerId] &&
      this.bannerProductListCacheExpired[bannerId] > Date.now()
    ) {
      return this.bannerProductList[bannerId];
    }
    const requestData: {
      warehouse_code: string;
      id?: string;
      deep_link?: string;
      isSafeRequest?: boolean;
    } = {
      warehouse_code: orderStore.etaCalculation.warehouse.code,
      isSafeRequest: mainStore.isSafeRequest || undefined,
    };
    if (isDeeplink) requestData.deep_link = bannerId;
    else requestData.id = bannerId;
    try {
      const { data } = await CatalogRequests.getBannerProducts(requestData);
      const output: BannerProductListValue = {
        categoryName: '',
        products: [],
      };
      if (!data) return output;
      output.categoryName = data.name;
      if (data.offer?.length) {
        data.offer.forEach((item) => {
          const product = convertProduct(item);
          if (!product) return;
          output.products.push(product);
        });
      }
      this.setBannerProductList({ ...output, bannerId });
      return output;
    } catch (e) {
      await this.errorHandler(e, 'requestBannerProducts');
    }
    return null;
  }

  async requestProduct(productId: string, isDeeplink: boolean): Promise<Product | null> {
    this.optimizeProductsCache();
    if (!orderStore.etaCalculation || !productId) return null;
    if (
      !isDeeplink &&
      this.products[productId] &&
      this.productsCacheExpired[productId] &&
      this.productsCacheExpired[productId] > Date.now()
    ) {
      return this.products[productId];
    }
    const requestData: ProductRequest = {
      warehouseCode: orderStore.etaCalculation.warehouse.code,
      deviceId: window.ReactNativeWebView ? userStore.deviceId : userStore.deviceId_frontOnly,
      isSafeRequest: mainStore.isSafeRequest || undefined,
    };
    if (userStore.isAuthorized) {
      requestData.customerId = userStore.personalData.id || undefined;
    }
    /*if (isDeeplink) {
      requestData.deepLink = productId;
    } else {
      requestData.id = productId;
    }*/
    try {
      const { data } = await CatalogRequests.getProduct(productId, requestData);
      const product = convertProduct(data);
      if (product && !isDeeplink) {
        this.setProducts(product);
      }
      return product;
    } catch (error) {
      error && console.error(error);
    }
    return null;
  }

  async requestSearch(q: string, source: 'idea' | 'hint' | 'history' | 'typein', limit: number) {
    try {
      const { data } = await CatalogRequests.getSearch({
        q: q,
        warehouse_code: orderStore.etaCalculation?.warehouse.code || '',
        device_id: window.ReactNativeWebView ? userStore.deviceId : userStore.deviceId_frontOnly,
        isSafeRequest: mainStore.isSafeRequest || undefined,
        limit,
      });
      if (!data) {
        this.setSearchProductList([]);
        this.setSearchCatsList([]);
        return;
      }
      const { products = [], categories = [] } = data;
      this.setSearchProductList(
        products.map((product) => convertProduct(product)).filter(
          (product) => product) as Product[],
      );
      this.setSearchCatsList(categories);
      if (products.length || categories.length) {
        this.updateSearchHistory(q);
      }
      if (source === 'typein' && this.searchIdeasList.indexOf(q) !== -1) {
        source = 'idea';
      }
      mainStore.sendToRN('analytics', {
        name: 'Catalog: searched',
        params: {
          query: q,
          results_num: products.length,
          source: source,
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'search',
        params: {
          search_term: q,
        },
      });
    } catch (e) {
      await this.errorHandler(e, 'requestSearch');
    }
  }

  async requestSearchHistory() {
    try {
      const { data } = await CatalogRequests.getSearchHistory({
        device_id: window.ReactNativeWebView ? userStore.deviceId : userStore.deviceId_frontOnly,
      });
      const history = (data || [])
        .map((el) => {
          return (el.search_phrase || '').search(/%\D|%$/g) === -1
            ? decodeURIComponent(el.search_phrase || '')
            : el.search_phrase || '';
        })
        .filter((el) => el) as string[];
      this.updateSearchHistory(history);
    } catch (e) {
      await this.errorHandler(e, 'requestSearchHistory');
    }
  }

  async deleteSearchHistory() {
    try {
      this.updateSearchHistory([]);
      await CatalogRequests.deleteSearchHistory({
        device_id: window.ReactNativeWebView ? userStore.deviceId : userStore.deviceId_frontOnly,
      });
    } catch (e) {
      await this.errorHandler(e, 'deleteSearchHistory');
    }
  }


  /**
   * @param isForce - ignore previous cache and upload new items from server.
   * Usually means that WH code was changed
   * */
  async requestBestsellers(isForce?: boolean): Promise<Product[]> {
    if (
      (!isForce
        && this.bestsellersProducts
        && this.bestsellersProducts.length
        && this.bestsellersProductsCacheExpired
        && this.bestsellersProductsCacheExpired > Date.now())
      || !orderStore.etaCalculation
    ) {
      return this.bestsellersProducts;
    }
    const products: Product[] = [];
    try {
      const { data = [] } = await CatalogRequests.getBestsellers({
        warehouse_code: orderStore.etaCalculation.warehouse.code,
        isSafeRequest: mainStore.isSafeRequest || undefined,
      });
      data.forEach((item) => {
        const product = convertProduct(item);
        if (!product) return;
        products.push(product);
      });
    } catch (error) {
      error && console.error(error);
    }
    this.setBestsellersProducts(products);
    return products;
  }

  async requestRecommendProducts(
    sku: string[],
    source: RecommendProductsSource,
  ): Promise<Product[] | null> {
    try {
      const { data } = await CatalogRequests.getRecommendProducts(
        orderStore.etaCalculation?.warehouse.code || '',
        {
          sku,
          source,
          isSafeRequest: mainStore.isSafeRequest || undefined,
        },
      );
      const products: Product[] = [];
      data.forEach((item) => {
        const product = convertProduct(item);
        if (!product) return;
        products.push(product);
      });
      if (source === 'purchased_items') this.setRecommendItems(products);
      return products;
    } catch (e) {
      return null;
    }
  }

  addFavorite(ids: { product_id: number }[]) {
    FavoritesRequest.addFavorite(ids).catch((e) => {
      this.errorHandler(e, 'addFavorite').catch((error) => error && console.error(error));
    });
  }

  removeFavorite(id: number) {
    FavoritesRequest.removeFavorite(id).catch((e) => {
      this.errorHandler(e, 'removeFavorite').catch((error) => error && console.error(error));
    });
  }

  async fetchFavorites() {
    if (!userStore.isAuthorized || !orderStore.etaCalculation) return;
    try {
      const { products } = await FavoritesRequest.getFavorites({
        warehouse_code: orderStore.etaCalculation.warehouse.code,
        isSafeRequest: mainStore.isSafeRequest || undefined,
      });
      if (!products?.length) return;
      const forEntries = products.map<[number, Product | null]>((product) => [
        product.id,
        convertProduct(product),
      ]).filter(([, product]) => product) as [number, Product][];
      this.setFavorites(Object.fromEntries(forEntries));
    } catch (e) {
      await this.errorHandler(e, 'fetchFavorites');
    }
  }

  async fetchPurchasedItems(isForce?: boolean): Promise<boolean> {
    if (!userStore.isAuthorized || !orderStore.etaCalculation) {
      return false;
    }

    try {
      if (isForce) {
        /**
         * Code below will reset uploaded items
         *
         * It is specific case for situation when user makes an order, and was navigated to home page,
         * On main page user should see at the beginning of carousel products that was bought in last order.
         *
         * To achieve that we need to reset all loaded purchase items and fetch it again.
         * */
        this.purchasedItemsPage = 1;
        this.purchasedItems = new Map<number, Product>();
      } else {
        this.purchasedItemsPage += 1;
      }

      const { data = [] } = await FavoritesRequest.getPurchasedItems(
        orderStore.etaCalculation.warehouse.code,
        this.purchasedItemsPage,
        PURCHASED_ITEMS_COUNT,
      );

      runInAction(() => {
        for (let productIndex = 0; productIndex < data.length; productIndex += 1) {
          const product = convertProduct(data[productIndex]);
          if (!product) continue;
          this.purchasedItems.set(product.id, product);
        }
      });

      return data?.length > 0;
    } catch (e) {
      e && console.error(e);
    }

    return false;
  }

  async requestPurchasedItems(limit: number): Promise<Product[]> {
    if (!userStore.isAuthorized || !orderStore.etaCalculation) {
      return [];
    }

    try {
      const { data = [] } = await FavoritesRequest.getPurchasedItems(
        orderStore.etaCalculation.warehouse.code,
        1,
        limit,
      );

      return data.map((item) => convertProduct(item)).filter((item) => item) as Product[];
    } catch (e) {
      e && console.error(e);
    }

    return [];
  }

  applyPromocode(promocodeName: string, source: string): Promise<boolean> {
    return OrderRequests.checkPromocode(promocodeName)
      .then((data) => {
        const promocode = this.validatePromocode(data, promocodeName);
        runInAction(() => {
          this.promocode = promocode;
          if (promocode.coupon) {
            mainStore.sendAnalytics(['BI', 'analytics'], {
              name: 'Purchase: promocode entered',
              params: {
                code: promocodeName,
                discount: promocode.coupon.type === 'FIXED'
                  ? mainStore.convertPenceToPounds(promocode.coupon.value || 0)
                  : promocode.coupon.value || 0,
                success: true,
                products_amount: this.cart.reduce((sum, item) => sum + item.count, 0),
                items_amount: this.cart.length,
                price: this.totalCartPrice.base,
                final_price: this.finalPrice,
                eta_min: orderStore.etaCalculation?.duration.min || 0,
                eta_max: orderStore.etaCalculation?.duration.max || 0,
                delivery_fee: orderStore.fee.shippingPounds || 0,
                threshold: orderStore.fee.thresholdPounds || 0,
                is_surger: orderStore.etaCalculation?.highDemand || false,
                type: promocode.coupon.type || '',
              },
            });
            mainStore.sendToRN('firebaseAnalytics', {
              name: 'select_promotion',
              params: {
                items: this.cartForFirebase,
                location_id: '',
              },
            });
          } else this.removeUsedPromocodes(promocodeName);
        });
        return !!promocode.coupon;
      })
      .catch(({ response }) => {
        runInAction(() => {
          let message = i18n.t('errors:promocodeError');
          if (response?.data?.data?.err_code) {
            message = i18n.t('errors:' + response?.data?.data?.err_code);
            if (source === 'checkout') {
              message =
                response?.data?.data?.err_code === 'DISCOUNT.COUPONS.ONLY_FIRST_ORDER_ALLOWED'
                  ? i18n.t('cartPage:notFirstOrderPromocodeFreeDelivery')
                  : i18n.t('cartPage:promocodeNotFoundText');
            }
          }
          this.promocode.coupon = null;
          this.promocode.value = promocodeName;
          this.promocode.errorType = 'error';
          this.promocode.message = message;
          this.promocode.success = false;
          this.removeUsedPromocodes(promocodeName);
        });
        return false;
      });
  }

  async checkProposedPromocodes() {
    const proposedPromocodes: ProposedPromocode[] = [];
    let isPresentUsed = false;
    if (
      userStore.isAuthorized &&
      userStore.personalData.id &&
      this.usedPromocodes[userStore.personalData.id] &&
      this.usedPromocodes[userStore.personalData.id].length
    ) {
      for (let i = 0; i < this.usedPromocodes[userStore.personalData.id].length; i++) {
        let isPresent = false;
        try {
          if (PRESENT_PROMOCODES.includes(
            this.usedPromocodes[userStore.personalData.id][i])) {
            isPresentUsed = true;
            isPresent = true;
          }
          const promocodeData = await OrderRequests.checkPromocode(
            this.usedPromocodes[userStore.personalData.id][i]);
          const promocode = this.validatePromocode(
            promocodeData, this.usedPromocodes[userStore.personalData.id][i]);
          if (!promocode.coupon) {
            this.removeUsedPromocodes(this.usedPromocodes[userStore.personalData.id][i]);
            continue;
          }
          const discount =
            promocode.coupon.type === 'PERCENTAGE'
              ? promocode.coupon.value + '%'
              : mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(promocode.coupon.value));
          if (isPresent) {
            proposedPromocodes.push({
              name: this.usedPromocodes[userStore.personalData.id][i],
              discount: discount,
              title: i18n.t(
                `statusWidget:promo.${promocode.coupon.type}.title`,
                {
                  minBasket: mainStore.addCurrencySymbol(
                    mainStore.convertPenceToPounds(promocode.coupon.minimumPurchase)),
                  discount: discount,
                },
              ),
              text: i18n.t(
                `statusWidget:promo.${promocode.coupon.type}.text`,
                {
                  promocode: this.usedPromocodes[userStore.personalData.id][i],
                  balance: orderStore.currencySymbol + promocode.discountLimit,
                  count: (promocode.coupon.usageLimitCustomer || 0) - (promocode.coupon.usageCurrent || 0),
                },
              ),
            });
          } else {
            proposedPromocodes.push({
              name: this.usedPromocodes[userStore.personalData.id][i],
              discount: discount,
              title: i18n.t('statusWidget:promo.title', {
                balance: orderStore.currencySymbol + promocode.discountLimit,
              }),
              text: i18n.t('statusWidget:promo.text', {
                promocode: this.usedPromocodes[userStore.personalData.id][i],
                discount: discount,
              }),
            });
          }
        } catch (error) {
          error && console.error(error);
          this.removeUsedPromocodes(this.usedPromocodes[userStore.personalData.id][i]);
        }
      }
    }
    if (!isPresentUsed && mainStore.analytics.totalSuccessfulOrders <= PRESENT_PROMOCODES_ORDERS_LIMIT) {
      try {
        let promocodeName = '';
        for (let i = 0; i < PRESENT_PROMOCODES.length; i++) {
          if (!PRESENT_PROMOCODES_DATA[PRESENT_PROMOCODES[i]]?.storeAllowed.length && !promocodeName) {
            promocodeName = PRESENT_PROMOCODES[i];
          } else if (PRESENT_PROMOCODES_DATA[PRESENT_PROMOCODES[i]]?.storeAllowed.includes(
            orderStore.etaCalculation?.warehouse.code || '')) {
            promocodeName = PRESENT_PROMOCODES[i];
          }
        }
        if (promocodeName) {
          const promocodeData = await OrderRequests.checkPromocode(promocodeName);
          const promocode = this.validatePromocode(promocodeData, promocodeName);
          if (promocode.coupon) {
            const discount =
              promocode.coupon.type === 'PERCENTAGE'
                ? promocode.coupon.value + '%'
                : mainStore.addCurrencySymbol(
                  mainStore.convertPenceToPounds(promocode.coupon.value));
            proposedPromocodes.push({
              name: promocodeName,
              discount: discount,
              title: i18n.t(
                `statusWidget:promo.${promocode.coupon.type}.title`,
                {
                  minBasket: mainStore.addCurrencySymbol(
                    mainStore.convertPenceToPounds(promocode.coupon.minimumPurchase)),
                  discount: discount,
                },
              ),
              text: i18n.t(
                `statusWidget:promo.${promocode.coupon.type}.text`,
                {
                  promocode: promocodeName,
                  balance: orderStore.currencySymbol + promocode.discountLimit,
                  count: (promocode.coupon.usageLimitCustomer || 0) - (promocode.coupon.usageCurrent || 0),
                },
              ),
            });
          }
        }
      } catch (error) {
        error && console.error(error);
      }
    }
    runInAction(() => {
      this.proposedPromocodes = proposedPromocodes;
    });
  }

  debouncedCalculateCart = debounce(() => {
    this.calculateCart().catch((error) => error && console.error(error));
  }, 500);

  /**
   * @return
   * true - cart items changed
   *
   * false - cart items not changed
   * */
  async calculateCart(): Promise<boolean> {
    if (!this.cart.length) {
      return false;
    }

    const currentRequestId = this.calculationProcess.requestId + 1;

    this.setCalculationProcess({
      requestId: currentRequestId,
      isError: false,
      isLoading: true,
    });

    const cartObj: Record<string, CartItem> = {};

    try {
      const requestedItems: CartCalculationItemRequest[] = [];
      const skuList: string[] = [];
      for (let i = 0; i < this.cart.length; i++) {
        requestedItems.push({
          sku: this.cart[i].sku,
          requested_quantity: this.cart[i].count,
        });
        skuList.push(this.cart[i].sku);
        cartObj[this.cart[i].sku] = this.cart[i];
      }
      const result = await CatalogRequests.calculateCart({
        address: {
          latitude: userStore.deliveryAddress?.coordinates.lat ?? 0,
          longitude: userStore.deliveryAddress?.coordinates.lng ?? 0,
        },
        delivery_method: checkoutStore.deliveryMethod,
        items: requestedItems,
        seller: 'jiffy',
        should_use_bonuses: checkoutStore.useBonuses,
        warehouse_code: orderStore.etaCalculation?.warehouse.code ?? '',
        // user can use bonuses or promocode only
        promocode: checkoutStore.useBonuses ? undefined : this.promocode.coupon?.code,
        tips_amount: mainStore.convertPoundsToPence(orderStore.orderTipsValue),
      });

      if (this.calculationProcess.requestId > currentRequestId) {
        // this means that user sends another request to server and this one no longer valid, so it should be ignored
        return false;
      }

      const {
        base_total: totalBasePence,
        discount_total: totalDiscountPence,
        paid_total: paidPence,
        received_bonuses,
        promocode,
        promocodeError,
        promocode_discount: promocodeDiscountPence,
        is_first_order,
        is_delivery_free,
        bonuses_to_spend,
        base_received_bonuses,
        service_fee,
        items,
        paid_bonuses,
        delivery_info,
        tax_percent,
        tax_amount,
      } = result;

      mainStore.sendToRN('sendTags', {
        cart_price: mainStore.toFloat(paidPence),
      });

      const amountLeftToDiscount = this.calculateAmountLeftToDiscount(
        totalBasePence,
        totalDiscountPence,
      );

      let isCartChanged = false;

      runInAction(() => {
        this.totalCartPrice = {
          baseEarnedBonusesPence: base_received_bonuses,
          bonusesToSpentPounds: bonuses_to_spend,
          amountLeftToDiscount,
          base: mainStore.convertPenceToPounds(totalBasePence),
          serviceFeePence: service_fee,
          discount: mainStore.convertPenceToPounds(totalDiscountPence),
          // all discounts that was applied for current cart
          paidWithDiscount: mainStore.convertPenceToPounds(
            totalBasePence - (totalDiscountPence + promocodeDiscountPence + paid_bonuses),
          ),
          paid: mainStore.convertPenceToPounds(paidPence),
          promocodeDiscount: mainStore.convertPenceToPounds(promocodeDiscountPence),
          earnedBonusesPence: received_bonuses,
          isFirstOrder: is_first_order,
          isFreeDelivery: is_delivery_free,
          paidBonusesPence: paid_bonuses,
          totalDeliveryPricePence: delivery_info.paid_price,
          items,
          taxPercent: tax_percent || 0,
          taxAmount: mainStore.convertPenceToPounds(tax_amount || 0),
        };

        this.setCalculationProcess({
          ...this.calculationProcess,
          isLoading: false,
        });

        if (this.cart.length) {
          isCartChanged = this.updateCartItems(skuList, cartObj, items);
        }

        if (promocode && promocodeError && promocodeError !== 'PROMOCODE_MINUMUM_PURCHASE') {
          this.promocode.errorType = 'error';
          this.promocode.message = i18n.t(
            `errors:${promocodeError}`, {
              count: this.promocode.coupon?.orderNumberRange[0] || 0,
            });
          this.promocode.success = false;
        }
      });

      return isCartChanged;
    } catch (error) {
      error && console.error(error);

      if (this.calculationProcess.requestId > currentRequestId) {
        // this means that user sends another request to server and this one no longer valid, so it should be ignored
        return false;
      }

      if (this.calculationProcess.requestId === currentRequestId) {
        this.setCalculationProcess({
          requestId: currentRequestId,
          isError: true,
          isLoading: false,
        });
      }

      runInAction(() => {
        if (error.response?.data?.data?.length) {
          this.outStockProductList = [];
          this.changeCountProductList = [];
          for (let i = 0; i < error.response.data.data.length; i++) {
            if (error.response.data.data[i].on_stock <= 0) {
              cartObj[error.response.data.data[i].sku].count = 0;
              this.outStockProductList.push(cartObj[error.response.data.data[i].sku]);
            } else {
              cartObj[error.response.data.data[i].sku].count = error.response.data.data[i].on_stock;
              this.changeCountProductList.push(toJS(cartObj[error.response.data.data[i].sku]));
            }
          }
          this.cart = Object.values(cartObj);
        }
      });
      if (
        this.outStockProductList.length ||
        this.changeCountProductList.length
      ) {
        mainStore.setIsShowOutStockPopover(true);
        return true;
      }
    }
    return false;
  }

  calculateAmountLeftToDiscount(baseTotalPence: number, discountTotalPence: number): string {
    const totalDiscountPence = baseTotalPence - discountTotalPence;

    if (!this.promocode.success || !this.promocode.coupon || this.isCharityPromocode) {
      return '0';
    }

    const {
      type,
      minimumPurchase,
      value: discountValuePence,
    } = this.promocode.coupon;

    if (totalDiscountPence < minimumPurchase) {
      const result = minimumPurchase - totalDiscountPence;
      return result < 0 ? '0' : mainStore.convertPenceToPounds(result);
    }

    if (type === 'FIXED' && totalDiscountPence < discountValuePence) {
      const result = discountValuePence - totalDiscountPence;
      return result < 0 ? '0' : mainStore.convertPenceToPounds(result);
    }

    return '0';
  }

  leftUntilFreeFee(totalPrice: number, feeThreshold: number): [string, string] {
    const zero: [string, string] = ['0', '100%'];
    if (!this.cart.length) return zero;

    if (totalPrice >= feeThreshold) return zero;
    const remainder = feeThreshold - totalPrice;
    const remainderPercent = 100 - (remainder * 100) / (feeThreshold || 1);
    return [mainStore.convertPenceToPounds(remainder), remainderPercent + '%'];
  }

  // Errors
  errorHandler = (error: AxiosError<ApiErrorResponse>, context: string): Promise<AxiosError> =>
    mainStore.errorHandler(error, context);
}

export const catalogStore = new CatalogStore();
