import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { mainStore } from '../stores/MainStore';
import PageHeader from '../components/PageHeader';
import ReferralBanner from '../components/ReferralBanner';
import { useTranslation } from 'react-i18next';
import ExternalLink from '../components/ExternalLink';
import { useLocation } from 'react-router-dom';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const { state = { source: '' } } = useLocation<{ source?: string }>();
  let discount = company.config.referral.discount;

  if (!discount.includes('%')) discount = mainStore.addCurrencySymbol(discount);

  const shareText = t(
    'referral:shareDesc',
    {
      discount,
      code: company.config.referral.code,
      minBasket: mainStore.addCurrencySymbol(company.config.referral.minBasket || ''),
      downloadLink: company.config.referral.downloadAppLink,
    },
  );

  useEffect(() => {
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Referral program: Open',
      params: {
        source: state.source || 'homepage',
      },
    });
  }, [state.source]);

  const handleShare = () => {
    mainStore.sendToRN('openShare', shareText);
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Referral program: Share',
      params: {},
    });
  };

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(shareText);
      mainStore.pushAlert('success', t('referral:promoCopied'));
    } catch (error) {
      error && console.error(error);
      mainStore.sendToRN(
        'copyToClipboard', shareText,
        () => {
          mainStore.pushAlert('success', t('referral:promoCopied'));
        }, () => {
          mainStore.pushAlert('error', t('errors:unknown'));
        },
      );
    }
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Referral program: Copy code',
      params: {},
    });
  };

  return (
    <>
      <PageHeader />
      <div className="scroll-layout px-24 d-flex flex-direction-column h-100p">
        <ReferralBanner className="mx-n8 pe-n" />
        <div className="mt-40  fs-21 lh-28 text-center px-10 c-black">
          {t('referral:desc', { discount })}
        </div>
        <div className="d-flex align-items-center fs-14 lh-19 c-black mt-35 px-18">
          <div className="icon icon-share d-flex flex-center s-24 fs-24 c-corange mr-16" />
          {t('referral:shareCode')}
        </div>
        <div className="d-flex align-items-center fs-14 lh-19 c-black mt-20 px-18">
          <div className="icon icon-star d-flex flex-center s-24 fs-24 c-corange mr-16" />
          {t('referral:yourFriendsGet', { discount })}
        </div>
        <div className="referral-code-box mt-32" onClick={handleCopyCode}>
          <div className="icon__copy mr-8" />
          {company.config.referral.code}
        </div>
        <div className=" text-center fs-10 c-dgray mt-8">
          {t('referral:tapBox')}
        </div>
        <div
          className="button _primary w-100p mt-auto d-flex"
          onClick={handleShare}
        >
          <div className="icon icon-share d-flex flex-center s-24 fs-18 mr-12" />
          {t('share')}
        </div>
        <div className="text-center w-100p mt-16">
          <ExternalLink
            className="c-blue fs-14 lh-19"
            to={{ pathname: '/inject', state: { page: 'terms' } }}
            href={company.config.links.terms}
          >
            {t('aboutPage:termsAndConditions')}
          </ExternalLink>
        </div>
        <div className="h-24 flex-shrink-0" />
      </div>
    </>
  );
});
