import { observer } from 'mobx-react-lite';
import { mainStore } from '../../../stores/MainStore';
import { catalogStore } from '../../../stores/CatalogStore';
import { orderStore } from '../../../stores/OrderStore';
import React from 'react';
import StoreOpensAt from './StoreOpensAt/StoreOpensAt';
import DeliveryTime from './DeliveryTime/DeliveryTime';
import FeesStatuses from './FeesStatuses/FeesStatuses';
import { FeeStatus } from './constants';
import { userStore } from '../../../stores/UserStore';
import FeeTracker from './FeeTracker/FeeTracker';

const BoardInfo = observer(() => {
  const handleShowETAPopover = () => mainStore.setIsShowETAPopover(true);

  const isPeakHours = orderStore.etaCalculation?.highDemand === true;

  const deliveryFeeSpent = orderStore.fee.shipping;
  const serviceFeeAmount = orderStore.fee.serviceFee ?? 0;

  const getMinimalOrderFeeStatus = () => {
    if (catalogStore.isMinimalOrderFeePassed) {
      return FeeStatus.Hide;
    }

    if (catalogStore.cart.length === 0) {
      return FeeStatus.Default;
    }

    return FeeStatus.NeedsToFree;
  };

  const getDeliveryFeeStatus = (): FeeStatus => {
    if (getMinimalOrderFeeStatus() === FeeStatus.NeedsToFree) {
      return FeeStatus.Default;
    }

    if (userStore.personalData.freeDeliveryDaysLeft > 0) {
      // user has free delivery days
      return FeeStatus.FreeDays;
    }

    if (catalogStore.cart.length === 0) {
      return FeeStatus.Default;
    }

    if (catalogStore.isFreeDelivery) {
      // user has free delivery option
      return FeeStatus.Free;
    }

    if (serviceFeeAmount === 0 || catalogStore.isFreeServiceFee ||
      deliveryFeeSpent < serviceFeeAmount
    ) {
      return FeeStatus.NeedsToFree;
    }

    return FeeStatus.Default;
  };

  const getServiceFeeStatus = (): FeeStatus => {
    if (getMinimalOrderFeeStatus() === FeeStatus.NeedsToFree) {
      return FeeStatus.Default;
    }

    if (orderStore.fee.serviceFeeThreshold === 0) {
      return FeeStatus.Hide;
    }

    if (catalogStore.isFreeServiceFee) {
      return FeeStatus.Free;
    }

    if (catalogStore.cart.length === 0) {
      return FeeStatus.Default;
    }

    if (deliveryFeeSpent === 0 || catalogStore.isFreeDelivery ||
      serviceFeeAmount <= deliveryFeeSpent
    ) {
      return FeeStatus.NeedsToFree;
    }

    return FeeStatus.Default;
  };

  const deliveryFeeStatus = getDeliveryFeeStatus();
  const serviceFeeStatus = getServiceFeeStatus();
  const minimalOrderFee = getMinimalOrderFeeStatus();

  if (!orderStore.isStoreAvailable) {
    return (
      <div className="w-100p">
        <StoreOpensAt />
      </div>
    );
  }

  return (
    <div className="w-100p ff-feixen-book" onClick={handleShowETAPopover}>
      <div className="d-flex align-items-center justify-content-between">
        <FeesStatuses
          serviceFeeStatus={serviceFeeStatus}
          deliveryFeeStatus={deliveryFeeStatus}
          minimalOrderFeeStatus={minimalOrderFee}
        />

        {catalogStore.cart.length === 0 && <DeliveryTime isPeakHours={isPeakHours} />}

        {catalogStore.cart.length > 0 &&
          (isPeakHours ? <DeliveryTime isPeakHours /> : <div className="icon-faq icon c-gray" />)}
      </div>

      {catalogStore.cart.length > 0 && (
        <>
          {minimalOrderFee === FeeStatus.NeedsToFree && (
            <FeeTracker progressWidth={catalogStore.leftUntilFreeMinimalOrderFee[1]} />
          )}

          {serviceFeeStatus === FeeStatus.NeedsToFree && (
            <FeeTracker progressWidth={catalogStore.leftUntilFreeServiceFee[1]} />
          )}

          {deliveryFeeStatus === FeeStatus.NeedsToFree && (
            <FeeTracker progressWidth={catalogStore.leftUntilFreeDelivery[1]} />
          )}

          <div className="pb-8" />
        </>
      )}
    </div>
  );
});

export default BoardInfo;
