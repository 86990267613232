import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import { orderStore } from '../../../../stores/OrderStore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoardInfoProps } from '../interface';

const DeliveryTime = observer((props: BoardInfoProps) => {
  const { isPeakHours } = props;
  const { t } = useTranslation();

  if (isPeakHours) {
    return (
      <div className="d-flex align-items-center flex-shrink-0">
        <div className="icon icon-flash s-14 d-flex flex-center mr-5 fs-14 c-red" />

        <div className="fs-11 c-red">{t('cartBoard:highDemand')}</div>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center flex-shrink-0">
      <div
        className={htmlClasses(
          'icon s-14 d-flex flex-center mr-5 fs-14',
          orderStore.etaCalculation?.highDemand ? 'icon-flash c-red' : 'icon-clock',
        )}
      />

      <div className="fs-11">
        {orderStore.etaCalculation?.duration.range || '?'} {t('min')}
      </div>
    </div>
  );
});

export default DeliveryTime;
