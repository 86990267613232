import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { mainStore } from '../stores/MainStore';

type SelectProps = {
  initialValue: string;
  data: {
    value: string;
    text: string;
  }[];
  onChange: (value: string) => void;
  className?: string;
};

export default observer(({ initialValue, data, onChange, className }: SelectProps) => {
  const [id] = useState(mainStore.getPseudoId());
  const [isExpanded, setIsExpanded] = useState(false);
  const [val, setVal] = useState(initialValue);
  const [index, setIndex] = useState(0);
  const handleBlur = () => setIsExpanded(false);

  useEffect(() => {
    setVal(initialValue);
  }, [initialValue]);

  return (
    <div className={htmlClasses('input-select', className, { _active: isExpanded })}>
      <input
        className="input-select__focus"
        type="text"
        id={`focus_${id}`}
        readOnly={true}
        onClick={() => setIsExpanded(true)}
        onBlur={handleBlur}
      />
      <div
        className={htmlClasses('input-select__val input-text__wrap', {
          _active: val !== initialValue,
        })}
      >
        <label className="link-abs" htmlFor={`focus_${id}`} />
        <div className="input-select__item pl-14 pr-54" dangerouslySetInnerHTML={{ __html: val }} />
        <div className="icon icon-chevron-down input-select__icon" />
        <div className="input-text__border" />
      </div>
      <div className="input-select__list">
        {data.map((item, i) => (
          <div
            className="input-select__item"
            key={item.value}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setIndex(i);
              setVal(item.text);
              onChange(item.value);
              setIsExpanded(false);
            }}
            dangerouslySetInnerHTML={{
              __html:
                item.text +
                (index === i
                  ? '<div class="icon icon-accepted s-24 fs-18 c-blue d-flex flex-center"/>'
                  : ''),
            }}
          />
        ))}
      </div>
    </div>
  );
});
