import { observer } from 'mobx-react-lite';
import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import { Order, OrderCartItem, OrderShort, OrderCartItemStatus, OrderAddress } from '../api/Order';
import LineThroughWrap from '../components/LineThroughWrap';
import PageHeader from '../components/PageHeader';
import { OrderItem } from './Orders';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import PopoverBrokenOrder from '../components/PopoverBrokenOrder';
import htmlClasses from 'html-classes';
import ExternalLink from '../components/ExternalLink';
import PopoverServiceFee from '../components/PopoverServiceFee';
import { company } from '../company/Company';

type OrderProductProps = {
  product: OrderCartItem;
  currency: string;
};

type DeliveryInstructionsAndCommentProps = {
  address: OrderAddress | null;
};

const OrderProduct = observer(({ product, currency }: OrderProductProps) => {
  const { t } = useTranslation();
  const sendAnalytic = () => {
    mainStore.sendToRN('analytics', {
      name: 'Catalog: product page clicked',
      params: {
        product_id: product.product_id,
        category_id: undefined,
        lvl1_category_id: undefined,
        lvl2_category_id: undefined,
        source: 'order',
        price: product.base_price,
        final_price: product.paid_price || product.base_price,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_product_page_clicked',
      params: {
        product_id: product.product_id,
        category_id: undefined,
        lvl1_category_id: undefined,
        lvl2_category_id: undefined,
        source: 'order',
        price: product.base_price,
        final_price: product.paid_price || product.base_price,
      },
    });
  };

  return (
    <div className="cart-item d-flex py-16 overflow-hidden position-relative">
      <div
        className="w-44 flex-shrink-0 img-contain position-relative"
        style={{ backgroundImage: product.image !== 'none' ? `url(${product.image})` : 'none' }}
      >
        <Link
          className="link-abs"
          to={{ pathname: `/product/${product.product_id}`, state: { source: 'order' } }}
          onClick={sendAnalytic}
        />
      </div>
      <div className="pl-8 w-100p d-flex flex-direction-column">
        <Link
          className="fs-12 lh-16 mb-4 flex-shrink-0"
          to={{ pathname: `/product/${product.product_id}`, state: { source: 'order' } }}
          onClick={sendAnalytic}
        >
          {product.name || ''}
        </Link>
        {product.discount_amount > 0 && !product.promo_quantity_discount_price && (
          <div className="br-3 c-bg-ulred fs-8 p-5 mb-4 c-red flex-shrink-0 lh-1 align-self-start">
            {t('specialOffer')}:{' '}
            <span className="fw-500">
              -{Math.floor((product.discount_amount * 100) / product.base_price)}%
            </span>
          </div>
        )}
        {!!product.promo_quantity_discount_price && (
          <div className="br-3 c-bg-ulred fs-8 p-5 mb-4 c-red flex-shrink-0 lh-1">
            {t('specialOffer')}:
            {' '}
            <span className="fw-500">
              {mainStore.addCurrencySymbol(
                mainStore.convertPenceToPounds(product.promo_quantity_discount_price), currency)}
              {' '}
              {t('from')}
              {' '}
              {product.promo_required_quantity}
              {t('pcs')}
            </span>
          </div>
        )}
        <div className="mt-auto flex-shrink-0 d-flex align-items-start">
          {product.discount_amount > 0 ? (
            <div className="product-item__price _discount fs-12 w-50 flex-shrink-0">
              {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(
                product.paid_price * (product.requested_quantity || 1),
              ), currency)}
              <s className="product-item__price-old fs-10 d-none">
                {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(
                  product.base_price * (product.requested_quantity || 1),
                ), currency)}
              </s>
            </div>
          ) : (
            <div className="product-item__price fs-12 w-50 flex-shrink-0">
              {mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(
                product.base_price * (product.requested_quantity || 1),
              ), currency)}
            </div>
          )}
          {product.actual_quantity !== null &&
          product.actual_quantity !== product.requested_quantity ? (
            <div className="fs-12 c-t99">
              {t(
                'collected',
                { num1: product.actual_quantity, num2: product.requested_quantity },
              )}{' '}
              / {mainStore.addCurrencySymbol(
              mainStore.convertPenceToPounds(product.base_price), currency)}
              <br />
              <span className="c-red">{t('outOfStock')}</span>
            </div>
          ) : product.price_per_unit ? (
            <div className="fs-12 c-t99">· {product.price_per_unit}</div>
          ) : (
            <div className="fs-12 c-t99">
              · 1 pc / {mainStore.addCurrencySymbol(
              mainStore.convertPenceToPounds(product.base_price), currency)}
            </div>
          )}
          <div className="flex-shrink-0 fs-12 ml-auto pl-8">{product.requested_quantity} pc</div>
        </div>
      </div>
    </div>
  );
});

const DeliveryInstructionsAndComment = observer(
  ({ address }: DeliveryInstructionsAndCommentProps) => {
    const { t } = useTranslation();

    if (!address) return <></>;

    return (
      <>
        {address.instructions && address.instructions.length > 0 && (
          <>
            <div className="fs-14 mb-12">{t('deliveryInstructions')}</div>
            {address.instructions.map((el, i) => (
              <div className="d-flex align-items-center mt-8" key={i}>
                <div className="icon icon-check-box s-18 d-flex flex-center flex-shrink-0 c-blue fs-18 mr-10" />
                <div className=" lh-19 fs-14">{el}</div>
              </div>
            ))}
          </>
        )}
        {address.comment && address.comment.length > 0 && (
          <>
            <div
              className={htmlClasses('fs-14 mb-8', {
                'mt-16': address.instructions && address.instructions.length > 0,
              })}
            >
              {t('comment')}
            </div>
            <div className="c-dgray fa-14 lh-19 text-break-word">{address.comment}</div>
          </>
        )}
        {((address.instructions && address.instructions.length > 0) ||
          (address.comment && address.comment.length > 0)) && (
          <div className="h-1 c-bg-tf2 mt-12 mb-14" />
        )}
      </>
    );
  },
);

export default observer(() => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<Order | OrderShort | null | undefined>(undefined);
  const [brokenOrderType, setBrokenOrderType] = useState<'missingItems' | 'notDelivered' | null>(
    null,
  );
  const [isShowPopoverMissingItems, setIsShowPopoverMissingItems] = useState(false);
  const handlePopoverMissingItemsDismiss = () => setIsShowPopoverMissingItems(false);
  const handlePopoverMissingItemsShow = () => setIsShowPopoverMissingItems(true);

  useLayoutEffect(() => {
    orderStore
      .requestOrder(id)
      .then((e) => setOrder(e))
      .catch((error) => error && console.error(error));
  }, [id]);

  useLayoutEffect(() => {
    if (!order) return;
    const itemBrokenStatuses: OrderCartItemStatus[] = [
      'missing',
      'picked_partially',
      'out_of_stock',
    ];
    const allItemsMissing = order.items.every(
      (item) => itemBrokenStatuses.indexOf(item.status) !== -1,
    );
    if (allItemsMissing) setBrokenOrderType('notDelivered');
    else {
      const someItemsMissing = order.items.some(
        (item) => itemBrokenStatuses.indexOf(item.status) !== -1,
      );
      if (someItemsMissing) setBrokenOrderType('missingItems');
    }
  }, [order]);

  const handleShowServiceFeePopover = () => mainStore.setIsShowServiceFeePopover(true);

  return (
    <>
      <PageHeader title={t('myOrder')} />
      <div className="scroll-layout h-100p px-24 pt-12">
        {order === undefined ? (
          <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7">
            <Skeleton className="sm-item br-8 h-200" />
          </SkeletonTheme>
        ) : order === null ? (
          <div className="text-center fs-14 c-gray p-20">
            {t('errors:failedOrderRequest')}
          </div>
        ) : (
          <>
            <div className="c-bg-white br-8 p-16 d-block border-tf2">
              <OrderItem order={order} isList={false} />
              <div className="h-1 c-bg-tf2 my-10" />
              {order.items.map((item) => (
                <OrderProduct product={item} currency={order.currency} key={item.id} />
              ))}
              <div className="h-1 c-bg-tf2 mt-10 mb-14" />
              <DeliveryInstructionsAndComment address={order.address} />
              <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                <div>{t('subtotal')}:</div>
                <div className="ml-10 text-right">
                  {mainStore.addCurrencySymbol(
                    mainStore.convertPenceToPounds(order.base_total || 0), order.currency)}
                </div>
              </div>
              {(order.promocode_discount || 0) > 0 && (
                <div className="d-flex align-items-start justify-content-between fs-16 mb-8 c-red">
                  <div>{t('promocode')}:</div>
                  <div className="ml-10 text-right">
                    - {mainStore.addCurrencySymbol(
                    mainStore.convertPenceToPounds(order.promocode_discount || 0), order.currency)}
                  </div>
                </div>
              )}
              {(order.discount_total || 0) > 0 && (
                <div className="d-flex align-items-start justify-content-between fs-16 mb-8 c-red">
                  <div>{t('discount')}:</div>
                  <div className="ml-10 text-right">
                    - {mainStore.addCurrencySymbol(
                    mainStore.convertPenceToPounds(order.discount_total || 0), order.currency)}
                  </div>
                </div>
              )}
              <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                <div className="d-flex align-items-center" onClick={handleShowServiceFeePopover}>
                  {t('etaPopover:serviceFee')}
                  <div className="ml-4 icon-faq icon c-gray" />
                </div>
                <div className="ml-10 text-right">
                  <LineThroughWrap isWrapped={mainStore.isZero(order.service_fee) || false}>
                    {mainStore.addCurrencySymbol(
                      mainStore.convertPenceToPounds(order.service_fee || 0), order.currency)}
                  </LineThroughWrap>
                </div>
              </div>
              {company.isShowTaxes &&
                <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                  <div>{t('etaPopover:taxes')}:</div>
                  <div className="ml-10 text-right">
                    {mainStore.addCurrencySymbol(
                      mainStore.convertPenceToPounds(order.tax_amount || 0),
                    )}
                  </div>
                </div>
              }
              <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                <div>{t('etaPopover:deliveryFee')}:</div>
                <div className="ml-10 text-right">
                  <LineThroughWrap isWrapped={order.is_delivery_free || false}>
                    {mainStore.addCurrencySymbol(
                      mainStore.convertPenceToPounds(order.delivery_price || 0), order.currency)}
                  </LineThroughWrap>
                </div>
              </div>
              <div className="h-1 pe-n c-bg-tf2 my-12" />
              <div className="d-flex align-items-start justify-content-between fw-500 fs-21">
                <div>{t('total')}:</div>
                <div className="ml-10 text-right">
                  {mainStore.addCurrencySymbol(
                    mainStore.convertPenceToPounds(order.paid_total || 0), order.currency)}
                </div>
              </div>
              {order.receipt_url && (
                <>
                  <div className="h-1 c-bg-tf2 mt-12" />
                  <div className="d-flex justify-content-center fs-14 lh-19  c-blue mt-14">
                    <ExternalLink className="p-8 c-blue" href={order.receipt_url}>
                      {t('getReceipt')}
                    </ExternalLink>
                  </div>
                </>
              )}
            </div>
            {brokenOrderType !== null && (
              <>
                <div
                  className="alert flex-direction-column justify-content-start align-items-start _warning mt-8"
                  onClick={handlePopoverMissingItemsShow}
                >
                  <div>
                    {brokenOrderType === 'notDelivered'
                      ? t('orderPage:weCouldntDeliver')
                      : t('orderPage:someItemsMissing')}
                    <div className="fs-14 c-blue">{t('moreInfo')}</div>
                  </div>
                </div>
                <PopoverBrokenOrder
                  isShow={isShowPopoverMissingItems}
                  onDismiss={handlePopoverMissingItemsDismiss}
                  type={brokenOrderType}
                  paymentMethod={order.payment_method}
                />
              </>
            )}
          </>
        )}
        <div className="h-24" />
      </div>
      <PopoverServiceFee />
    </>
  );
});
