import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { scrollTo } from '../../../UI';
import { GoToRecommendationProps } from './interface';
import { OBSERVER_CONFIG } from './constants';
import { mainStore } from '../../../stores/MainStore';
import { catalogStore } from '../../../stores/CatalogStore';
import { orderStore } from '../../../stores/OrderStore';
import htmlClasses from 'html-classes';

const GoToRecommendationButton = observer((props: GoToRecommendationProps) => {
  const { recommendProducts } = props;
  const { t } = useTranslation();

  const { runObserver, isObservableElementVisible } = useIntersectionObserver(OBSERVER_CONFIG);

  const recommendationsListRef = useRef<Nullable<HTMLDivElement>>(null);
  const [isShowGoToRecommendations, setIsShowGoToRecommendations] = useState(false);
  useLayoutEffect(() => {
    if (!recommendProducts?.length) {
      return;
    }

    recommendationsListRef.current = document.getElementById(
      'recommendations-list',
    ) as HTMLDivElement;
    runObserver(recommendationsListRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendProducts.length]);

  useEffect(
    () => setIsShowGoToRecommendations(!isObservableElementVisible),
    [isObservableElementVisible],
  );

  const handleScrollToRecommends = () => {
    mainStore.sendAnalytics(['analytics'], {
      name: 'Recommended products button clicked',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce((sum, item) => sum + item.count, 0),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });

    scrollTo('scroll-layout', document.getElementById('recommendations-list'), 200);
  };

  if (!isShowGoToRecommendations || recommendProducts.length === 0) {
    return <></>;
  }

  return (
    <div
      className={htmlClasses('recommendations-go-to-list', {
        "_minimal-order": !catalogStore.isMinimalOrderFeePassed
      })}
      onClick={handleScrollToRecommends}
    >
      <div className="button">
        {t('cartBoard:recommendedItems')}

        <div className="icon icon-chevron-down ml-8 fs-10" />
      </div>
    </div>
  );
});

export default GoToRecommendationButton;
