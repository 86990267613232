import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Order, OrderCartItemStatus } from '../../api/Order';
import LogoJiffy from '../../assets/img/logo_jiffy.svg';
import LogoBaqal from '../../assets/img/logo_baqal.svg';
import LogoSwifftB from '../../assets/img/logo_swifft_b.png';
import AnimationDelivered from '../../assets/lottie/delivered.json';
import AnimationCheck from '../../assets/lottie/check.json';
import AnimationShoppingBag from '../../assets/lottie/shopping_bag.json';
import AnimationInDelivery from '../../assets/lottie/in_delivery.json';
import PageHeader from '../../components/PageHeader';
import { REQUEST_ORDER_STATUS_INTERVAL } from '../../config';
import { OrderStatusStore, orderStore } from '../../stores/OrderStore';
import {
  ReactComponent as BlockedIcon,
} from '../../assets/img/order-status-icons/icon-blocked.svg';
import { userStore } from '../../stores/UserStore';
import { mainStore } from '../../stores/MainStore';
import PopoverBrokenOrder from '../../components/PopoverBrokenOrder';
import PopoverSubscribe from '../../components/PopoverSubscribe';
import { useLottie } from 'lottie-react';
import Popover from '../../components/Popover';
import InputText from '../../components/InputText';
import ReferralBanner from '../../components/ReferralBanner';
import { COURIER_TRACKING_URL } from './constants';
import QRCodeWidget from '../../components/QRCodeWidget/QRCodeWidget';
import { ETADeliveryMethodType, ETAPaymentMethod } from '../../api/ETA';
import ExternalLink from '../../components/ExternalLink';
import { CompanyName } from '../../company/interface';
import { company } from '../../company/Company';
import FCButton from '../../components/FCButton';

type IconsProps = {
  orderStatus: OrderStatusStore;
  deliveryMethod: ETADeliveryMethodType;
};

const LottieDelivered = observer(() => {
  const options = {
    animationData: AnimationDelivered,
    loop: true,
    autoplay: true,
  };
  const { View } = useLottie(options);

  return View;
});

const LottieCheck = observer(() => {
  const options = {
    animationData: AnimationCheck,
    loop: true,
    autoplay: true,
  };
  const { View } = useLottie(options);

  return View;
});

const LottieShoppingBag = observer(() => {
  const options = {
    animationData: AnimationShoppingBag,
    loop: true,
    autoplay: true,
  };
  const { View } = useLottie(options);

  return View;
});

const LottieInDelivery = observer(() => {
  const options = {
    animationData: AnimationInDelivery,
    loop: true,
    autoplay: true,
  };
  const { View } = useLottie(options);

  return View;
});

const statusIcons = [
  <LottieCheck />,
  <LottieShoppingBag />,
  <LottieInDelivery />,
  <LottieDelivered />,
];

const logo: Record<CompanyName, string> = {
  [CompanyName.Jiffy]: LogoJiffy,
  [CompanyName.Baqal]: LogoBaqal,
  [CompanyName.Swifft]: LogoSwifftB,
};

const Awaiting = observer(() => {
  const { t } = useTranslation();
  const { state } = useLocation<{ linkBack?: string }>();

  return (
    <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7">
      <PageHeader title={t('orderTracking')} linkBack={state?.linkBack || 'back'} />
      <div className="h-100p overflow-hidden px-40 pt-16">
        <img className="pe-n w-120 flex-shrink-0" src={logo[company.name]} alt="" />
        <Skeleton className="h-20 w-150 mt-24 br-30 d-block" />
        <Skeleton className="h-20 w-312 mt-10 br-30 d-block" />
        <Skeleton className="h-20 mt-24 br-30 d-block" />
        <div className="d-flex align-items-start justify-content-between flex-shrink-0 mt-30 mb-40 pe-n w-100p">
          {[1, 2, 3, 4].map((item, i) => (
            <Skeleton className="s-60 rounded-circle" key={i} />
          ))}
        </div>
      </div>
    </SkeletonTheme>
  );
});

const Icons = observer(({ orderStatus, deliveryMethod }: IconsProps) => {
  const icons = ['check-long', 'bag', 'indelivery', 'flag'];
  const progress = ['w-25p', 'w-50p', 'w-75p', 'w-100p', 'w-25p', 'w-100p'];
  let activeIndex = 0;
  switch (orderStatus) {
    case 'accepted':
      activeIndex = 0;
      break;
    case 'picking':
      activeIndex = 1;
      break;
    case 'readyToShip':
      activeIndex = 1;
      break;
    case 'readyForPickup':
      activeIndex = 2;
      break;
    case 'inDelivery':
      activeIndex = 2;
      break;
    case 'delivered':
      activeIndex = 3;
      break;
    case 'cancelled':
      activeIndex = 4;
      break;
    case 'failed':
      activeIndex = 5;
      break;
  }

  return (
    <>
      <div className="c-bg-bg pe-n br-13 mt-24 flex-shrink-0 w-100p">
        <div
          className={htmlClasses(
            'br-13 h-4',
            progress[activeIndex],
            `c-bg-${activeIndex > 3 ? 'red' : 'dgreen'}`,
          )}
        />
      </div>
      <div className="d-flex align-items-start justify-content-between flex-shrink-0 mt-16 mb-40 pe-n w-100p">
        {activeIndex <= 3
          ? icons.map((item, i) => (
            <div
              className={htmlClasses(
                'status-icon s-60 rounded-circle icon d-flex flex-center',
                i > activeIndex ? 'icon-' + item : '',
                {
                  'icon-clock': deliveryMethod === ETADeliveryMethodType.ClickAndCollect && item === 'indelivery',
                  'icon-check-long _checked': i < activeIndex,
                  _active: i === activeIndex,
                },
              )}
              key={i}
            >
              {i === activeIndex && (deliveryMethod === ETADeliveryMethodType.ClickAndCollect && item === 'indelivery' ? '' : statusIcons[activeIndex])}
            </div>
          ))
          : activeIndex === 4
            ? icons.map((item, i) => (
              <div
                className={htmlClasses(
                  'status-icon s-60 rounded-circle icon d-flex flex-center',
                  i > 0 ? 'icon-' + item : '_failed',
                )}
                key={i}
              >
                {i === 0 && <BlockedIcon />}
              </div>
            ))
            : icons.map((item, i) => (
              <div
                className={htmlClasses(
                  'status-icon s-60 rounded-circle icon d-flex flex-center',
                  i < 3 ? '_checked icon-' + item : '_failed',
                )}
                key={i}
              >
                {i === 3 && <BlockedIcon />}
              </div>
            ))}
      </div>
    </>
  );
});

interface IRateAppForm {
  orderStatus: OrderStatusStore;
}

const RateAppForm = observer(({ orderStatus }: IRateAppForm) => {
  const [isShow, setIsShow] = useState(true);
  const [isShowFeedbackPopover, setIsShowFeedbackPopover] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (company.isIncludedIn([CompanyName.Baqal])) {
      setIsShow(false);
      return;
    }
    const statusesForShowForm: OrderStatusStore[] = ['accepted', 'picking', 'readyToShip'];
    if (statusesForShowForm.includes(orderStatus) && !userStore.personalData.isRateApp) {
      setIsShow(true);
    } else setIsShow(false);
  }, [orderStatus, userStore.personalData.isRateApp]);

  const noHandler = () => {
    setIsShowFeedbackPopover(true);
  };

  const yesHandler = () => {
    mainStore.sendToRN('showRatePopup', null, (e) => {
      if (e.answer === 'NotNow') return;
      userStore.setIsRateApp(true);
      mainStore.pushAlert('success', t('rateAppForm:thanksFeedback'));
    });
  };

  const sendHandler = () => {
    mainStore.sendToRN('analytics', {
      name: 'Event: App feedback',
      params: {
        message: feedbackText,
      },
    });
    setIsShowFeedbackPopover(false);
    setFeedbackText('');
    mainStore.pushAlert('success', t('rateAppForm:thanksFeedback'));
  };

  return isShow ? (
    <>
      <div className="w-100p d-flex justify-content-center fs-18 lh-24">
        {t('rateAppForm:companyWorking')}
      </div>
      <div className="w-100p d-flex justify-content-center  fs-12 lh-16 c-mgray mt-4">
        {t('rateAppForm:rateExperience')}
      </div>
      <div className="w-100p d-flex justify-content-between mt-12">
        <div className="button _secondary mr-7 flex-grow-1" onClick={noHandler}>
          {t('no')}
        </div>
        <div className="button _primary flex-grow-1" onClick={yesHandler}>
          {t('yes')}
        </div>
      </div>
      <Popover
        isShow={isShowFeedbackPopover}
        onBackdropDismiss={() => setIsShowFeedbackPopover(false)}
      >
        <div className="fs-21 lh-28">{t('rateAppForm:whatsProblem')}</div>
        <InputText className="mt-10" value={feedbackText}>
          <textarea
            className="input-textarea"
            rows={isFocused ? 2 : 1}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={t('rateAppForm:whatHappened')}
            onChange={(e) => setFeedbackText(e.currentTarget.value)}
            value={feedbackText}
          />
        </InputText>
        <div className="w-100p d-flex justify-content-between mt-12">
          <div
            className="button _secondary mr-7 flex-shrink-1 w-100p"
            onClick={() => setIsShowFeedbackPopover(false)}
          >
            {t('notNow')}
          </div>
          <div
            className={htmlClasses('button _primary flex-shrink-1 w-100p', {
              _disabled: !feedbackText.length,
            })}
            onClick={sendHandler}
          >
            {t('send')}
          </div>
        </div>
      </Popover>
    </>
  ) : (
    <></>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ linkBack?: string }>();
  const [orderStatus, setOrderStatus] = useState<OrderStatusStore | null>(null);
  const [onfleetTrackingUrl, setOnfleetTrackingUrl] = useState<string | null>(null);
  const [shortOrderId, setShortOrderId] = useState<string>();
  const [isBrokenOrder, setIsBrokenOrder] = useState(false);
  const [isShowPopoverMissingItems, setIsShowPopoverMissingItems] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState<ETADeliveryMethodType>(
    ETADeliveryMethodType.JiffyDelivery);
  const [paymentMethod, setPaymentMethod] = useState<ETAPaymentMethod>(ETAPaymentMethod.Card);
  const [warehouseAddress, setWarehouseAddress] = useState('');
  const [warehouseLocation, setWarehouseLocation] = useState<GeoCoordinates | null>(
    null);
  const [deliverByMax, setDeliverByMax] = useState('');
  const iframeUrl = useMemo<string>(() => {
    if (deliveryMethod === ETADeliveryMethodType.ClickAndCollect) return '';
    return `${COURIER_TRACKING_URL[mainStore.environment]}?order_id=${shortOrderId}`;
  }, [shortOrderId, onfleetTrackingUrl, deliveryMethod]);
  const handlePopoverMissingItemsDismiss = () => setIsShowPopoverMissingItems(false);
  const handlePopoverMissingItemsShow = () => setIsShowPopoverMissingItems(true);
  const correctOrderId = (id: string): string => {
    return id.length >= 14 ? id.replace(/(.{6})$/, '-$1') : id;
  };
  const getDescMessage = (): JSX.Element => {
    if (deliveryMethod === ETADeliveryMethodType.ClickAndCollect && (orderStatus === 'accepted' || orderStatus === 'picking' || orderStatus === 'readyToShip' || orderStatus === 'readyForPickup' || orderStatus === 'inDelivery')) {
      return (
        <>
          <span className="c-gray">{t('pickupFrom')}</span>{' '}{warehouseAddress}
        </>
      );
    }
    const timeSplit = deliverByMax ? new Date(deliverByMax).toLocaleString(
      //'en-EN',
      //{ timeZone: company.config.timeZone },
    ).split(' ') : [];
    return (
      <span dangerouslySetInnerHTML={{
        __html: t(
          `statusWidget:${orderStatus}.desc`,
          {
            time: timeSplit.length ? `${timeSplit[1].slice(
              0, -3)}${timeSplit[2] ? ' ' + timeSplit[2] : ''}` : '',
          },
        ),
      }} />
    );
  };

  useLayoutEffect(() => {
    if (!userStore.isAuthorized) return;
    if (!id) return;
    if (
      orderStatus === 'delivered' ||
      orderStatus === 'none' ||
      orderStatus === 'failed' ||
      orderStatus === 'cancelled'
    )
      return;
    const getOrderStatus = () => {
      if (mainStore.inBackground) return;
      orderStore
        .requestOrder(id)
        .then((e) => {
          if (!e) {
            setOrderStatus('none');
            return;
          }
          setShortOrderId(correctOrderId(e.order_id));
          setDeliveryMethod(e.delivery_method);
          setPaymentMethod(e.payment_method);
          setWarehouseAddress(
            (e as Order).warehouse_address + (e.distance_to_warehouse > 0 ? ` (${e.distance_to_warehouse}m)` : ''));
          setDeliverByMax((e as Order).sla?.deliver_by_max || '');
          if (e.warehouse_location_latitude && e.warehouse_location_longitude) {
            setWarehouseLocation({
              lat: e.warehouse_location_latitude,
              lng: e.warehouse_location_longitude,
            });
          }
          if (e.public_status === 'in_delivery') {
            setOrderStatus('inDelivery');
            setOnfleetTrackingUrl((e as Order).delivery?.tracking?.dropoff || null);
          } else {
            let status: OrderStatusStore;
            switch (e.public_status) {
              case 'ready_to_ship':
                status = 'readyToShip';
                break;
              case 'ready_for_pickup':
                status = 'readyForPickup';
                break;
              default:
                status = e.public_status;
                break;
            }
            setOrderStatus(status);
          }
          const itemBrokenStatuses: OrderCartItemStatus[] = [
            'missing',
            'picked_partially',
            'out_of_stock',
          ];
          const brokenOrder = e.items.some(
            (item) => itemBrokenStatuses.indexOf(item.status) !== -1,
          );
          if (brokenOrder) setIsBrokenOrder(true);
        })
        .catch(() => {
          setOrderStatus('none');
        });
    };
    getOrderStatus();
    const interval = setInterval(getOrderStatus, REQUEST_ORDER_STATUS_INTERVAL);
    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [id, orderStatus, userStore.isAuthorized, mainStore.inBackground]);

  useLayoutEffect(() => mainStore.checkAndSetSubscribePopover('all'), []);

  useEffect(() => {
    if (orderStatus === 'none' || orderStatus === 'delivered') history.replace('/');
  }, [orderStatus, history]);

  if (!orderStatus) return <Awaiting />;

  if (orderStatus === 'none') return <Awaiting />;

  return (
    <>
      <PageHeader title={t('orderTracking')} linkBack={state?.linkBack || 'back'} />
      <div className="h-100p d-flex flex-direction-column justify-content-between overflow-auto px-28">
        <div className="flex-shrink-0 pt-16">
          <img className="pe-n flex-shrink-0 w-120" src={logo[company.name]} alt="" />
          <div className="mt-16 fs-14 c-udgray">
            {t('order')} #{shortOrderId}
          </div>
          <div className="mt-3 fs-21">
            {t(`statusWidget:${orderStatus}.name`)}
          </div>
          <div className="fs-14 lh-22 mt-12">{getDescMessage()}</div>
          <Icons orderStatus={orderStatus} deliveryMethod={deliveryMethod} />
          {company.isShowRateExperience && <RateAppForm orderStatus={orderStatus} />}
          {orderStatus === 'readyForPickup' && (
            <>
              <div className="mb-30 w-100p">
                <div className="fs-14 mb-5">
                  {t(`statusWidget:${orderStatus}.messageQR`)}
                </div>
                <QRCodeWidget className="mt-16" value={id || ''} />
              </div>
              {warehouseLocation !== null && (
                <div className="mb-30 w-100p">
                  <div className="fs-14 mb-5">{t(
                    `statusWidget:${orderStatus}.messageMap`)}</div>
                  <Link
                    className="fs-14 c-blue"
                    to={{
                      pathname: `/tracking-warehouse`,
                      state: { location: warehouseLocation },
                    }}
                  >
                    {t('showMap')}
                    <span className="icon icon-chevron-right fs-10 d-inline-block vertical-align-middle ml-5" />
                  </Link>
                </div>
              )}
            </>
          )}
          {orderStatus === 'inDelivery' && !company.isHideTracking && iframeUrl.length > 0 && (
            <div className="mb-30 w-100p">
              <div className="fs-14 mb-5">{t(`statusWidget:${orderStatus}.message`)}</div>
              <Link
                className="fs-14 c-blue"
                to={{
                  pathname: `/tracking/${id}`,
                  state: { iframeUrl },
                }}
              >
                {t('showMap')}
                <span className="icon icon-chevron-right fs-10 d-inline-block vertical-align-middle ml-5" />
              </Link>
            </div>
          )}
          {(orderStatus === 'delivered' || orderStatus === 'failed') && (
            <div className="mb-30 w-100p">
              <div className="fs-14 mb-5">{t(`statusWidget:${orderStatus}.message`)}</div>
              <Link className="fs-14 c-blue" to="/">
                {t('goMarket')}
                <span className="icon icon-chevron-right fs-10 d-inline-block vertical-align-middle ml-5" />
              </Link>
            </div>
          )}
        </div>
        <div>
          {isBrokenOrder && (
            <div className="alert _warning mb-16" onClick={handlePopoverMissingItemsShow}>
              <div>
                {t('orderTrackingPage:someItemsMissing')}
                <div className="fs-14 c-blue">{t('moreInfo')}</div>
              </div>
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between fs-16 c-blue w-100p">
            <Link className="w-100p text-center c-blue" to={`/order/${id}`}>
              {t('myOrder')}
            </Link>
            <div className="w-1 h-30 c-bg-teb flex-shrink-0" />
            {company.isIncludedIn([CompanyName.Baqal]) ? (
              <ExternalLink
                className="w-100p text-center c-blue"
                href={company.config.links.whatsapp}
                targetBlank
              >
                {t('getHelp')}
              </ExternalLink>
            ) : <FCButton className="w-100p text-center c-blue" title={t('getHelp')} />}
          </div>
          {['accepted', 'picking', 'readyToShip'].includes(orderStatus) && (
            <ReferralBanner source="order tracking page" className="mt-16 mx-n8" />
          )}
          <div className="h-24 flex-shrink-0" />
        </div>
        <PopoverSubscribe source="orderTracking" />
      </div>
      <PopoverBrokenOrder
        isShow={isShowPopoverMissingItems}
        onDismiss={handlePopoverMissingItemsDismiss}
        type="missingItems"
        paymentMethod={paymentMethod}
      />
    </>
  );
});
